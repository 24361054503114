import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { FC, useRef } from 'react'
import { useMutation } from 'react-query'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

type Props = {
  id: string
  updateData?: () => void
}

const DeleteAds: FC<Props> = ({ id, updateData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { onSuccess } = useAlert()
  const { mutate, isLoading } = useMutation(() => fetch('DELETE', `/banner/${id}`), {
    onSuccess: () => {
      updateData?.()
      onClose()
      onSuccess()
    },
  })

  return (
    <>
      <img data-test='delete-ads' src='/assets/delete.svg' style={{ cursor: 'pointer' }} onClick={onOpen} />
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg='#00000044' />
        <ModalContent data-test='delete-ads-modal' top='-60px' borderRadius={'15px'} style={{ boxShadow: '0px 4px 15px 0px #00000026' }}>
          <ModalHeader style={{ direction: 'rtl', fontWeight: 'lighter' }}>{dictionary().DELETE_ADS} </ModalHeader>
          <ModalBody pb={6}>
            <Box dir='rtl'>{dictionary().ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ADS}</Box>
          </ModalBody>
          <ModalFooter dir='rtl'>
            <Button mx={3} onClick={onClose}>
              {dictionary().CANCEL}
            </Button>
            <Button
              pb={isLoading ? 0 : 2}
              data-test='delete-ads-button'
              colorScheme='#red'
              sx={{ backgroundColor: '#B42318' }}
              onClick={() => mutate()}
              isLoading={isLoading}
            >
              {dictionary().REMOVE}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteAds
