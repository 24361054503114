import { Button, Flex, FormControl, GridItem, Icon, ModalBody, ModalFooter, ModalHeader, Spinner, Text } from '@chakra-ui/react'
import moment from 'moment'
import { FC, useState } from 'react'
import { FiFile } from 'react-icons/fi'
import dictionary from '../../../dictionary'
import { useDetailsStore } from '../../../stores/detailsStore'
import DownloadButton from '../DownloadButton/DownloadButton'
import FileUpload from '../FileUpload'
import { Actions, FileUploadForm } from './BulkUploadType'
import { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form'
import BulkUploadErrorMessage from './BulkUploadErrorMessage'

type Props = {
  close: () => void
  action: Actions
  initialFileUploadMutation: {
    initialFileUploadIsLoading: boolean
    initialFileUploadError: { data: { message: string; statusCode: number } } | any
    initialFileUploadIsError: boolean
    initialFileUploadReset: () => void
  }
  handleSubmit: UseFormHandleSubmit<FileUploadForm>
  onSubmit: () => void
  register: UseFormRegister<FileUploadForm>
}
const InitialModal: FC<Props> = ({ action, initialFileUploadMutation, handleSubmit, onSubmit, register, close }) => {
  const { details } = useDetailsStore()
  const [fileName, setFileName] = useState<string | null>(null)

  return (
    <>
      <ModalHeader style={{ direction: 'rtl' }} fontWeight='600' fontSize='15px' color='#1B202B' width={'521px'}>
        {action?.title}
      </ModalHeader>
      {initialFileUploadMutation?.initialFileUploadIsLoading ? (
        <Flex justifyContent={'center'} alignItems={'center'} w='full'>
          <Spinner size={'lg'} />
        </Flex>
      ) : (
        <ModalBody pb={2}>
          <GridItem style={{ direction: 'rtl' }} mb={1}>
            <Flex align='center' gap='15px'>
              <>
                <DownloadButton
                  text={dictionary().DOWNLOAD_CSV}
                  endPoint={action.apiHeader}
                  fileName={`${action.fileName} ${details?.name} ${moment().format('D-M-YYYY h:mm A')}`}
                />
              </>

              <form id='uploadform' onSubmit={handleSubmit(onSubmit)}>
                <FormControl isRequired>
                  <Flex style={{ direction: 'rtl' }} justifyContent='end'>
                    <FileUpload setFileName={setFileName} register={register('file_asset', { required: dictionary().REQUIRED })}>
                      <Button
                        onClick={() => {
                          initialFileUploadMutation?.initialFileUploadReset()
                        }}
                        backgroundColor='#EEF2F6'
                        borderRadius='5px'
                        padding='10px'
                        fontWeight='500'
                        fontSize='12px'
                        leftIcon={<Icon as={FiFile} />}
                      >
                        {dictionary().PICK_FILE}
                      </Button>
                    </FileUpload>
                  </Flex>
                </FormControl>
              </form>
            </Flex>
            <Text style={{ direction: 'rtl' }} fontWeight='500' fontSize='14px' color={'#000000'} mt={3}>
              {fileName}
            </Text>
            {initialFileUploadMutation?.initialFileUploadIsError && (
              <BulkUploadErrorMessage message={initialFileUploadMutation?.initialFileUploadError?.data?.message} />
            )}
          </GridItem>
        </ModalBody>
      )}

      <ModalFooter display='flex' justifyContent='flex-start' alignItems='flex-start'>
        <Button onClick={onSubmit} colorScheme='blue' mr={3}>
          {initialFileUploadMutation?.initialFileUploadIsLoading ? <Spinner /> : dictionary().SAVE}
        </Button>
        <Button onClick={close}>{dictionary().CANCEL}</Button>
      </ModalFooter>
    </>
  )
}

export default InitialModal
