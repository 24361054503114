import { useRef, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Modal,
  Spinner,
  Select,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormLabel,
  Button,
  FormControl,
  Input,
  useDisclosure,
  MenuItem,
  Flex,
  InputGroup,
  FormErrorMessage,
  InputLeftElement,
} from '@chakra-ui/react'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import Reqiured from '../../components/core/reqiured'

interface Props {
  action?: any
  handleIsBalanseChanged?: () => void
}

const TranseferBetweenEntitiesModal: React.FC<Props> = ({ action, handleIsBalanseChanged }) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { onSuccess, onError } = useAlert()
  // const { isDriverAssigned, setIsDriverAssigned } = UseAutoUpdateVaribles()

  let query: any = {}
  const [corporate, setCorporate] = useState([])
  const [isLoadingCorporate, setisLoading] = useState(true)
  const { pathname } = useLocation()
  if (pathname.includes('vehicles')) {
    query = useQuery(['vehicles', id], () => fetch('GET', `/vehicles/${id}?app=dashboard`), {})
  } else if (pathname.includes('drivers')) {
    query = useQuery(['drivers', id], () => fetch('GET', `/drivers/${id}`), {})
  }
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/corporates/transfer-balance-between-corporate-entities', { data: vars }), {
    onSuccess: (): void => {
      handleIsBalanseChanged && handleIsBalanseChanged()
      onSuccess()
      onClose()
    },
    onError,
  })

  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      fetch('GET', `/corporates/${query?.data?.corporate?.id}`).then((data) => {
        setisLoading(false)
        action.title === dictionary().TRANSFER_TO_DRIVER ? setCorporate(data.drivers) : setCorporate(data.vehicles)
      })
    }
  }, [query?.data?.vehicle, query?.data?.id, isOpen])

  const AddTransfer = async (formData: any) => {
    formData.account_type = 'FUEL'
    formData.corporateId = query?.data?.corporate?.id

    if (history?.location?.pathname.includes('drivers')) {
      formData.first_ref_type = 'driver'
      formData.first_ref_id = query?.data?.id
    } else if (history?.location?.pathname.includes('vehicles')) {
      formData.first_ref_type = 'vehicle'
      formData.first_ref_id = query?.data?.vehicle?.id
    }

    action.title === dictionary().TRANSFER_TO_DRIVER ? (formData.second_ref_type = 'driver') : (formData.second_ref_type = 'vehicle')

    mutate(formData)
  }

  const handleOnOpen = () => {
    onOpen()
    setValue('transfer_direction', null)
    setValue('amount', null)
    setValue('second_ref_id', null)
    error.data = {}
  }

  return (
    <>
      <MenuItem onClick={handleOnOpen}>{action.title}</MenuItem>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{action.title} </ModalHeader>
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(AddTransfer as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl
                  dir='rtl'
                  id={dictionary().TRASEFER_DIRECRTION}
                  w={['100%', '70%', '70%', '70%']}
                  display='inline-block'
                  isInvalid={!!errors.transfer_direction}
                >
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {dictionary().TRASEFER_DIRECRTION}
                      <Reqiured />
                    </>
                  </FormLabel>
                  <Select
                    dir='ltr'
                    textAlign='right'
                    placeholder={dictionary().PICK}
                    {...register('transfer_direction', {
                      required: dictionary().REQUIRED,
                    })}
                  >
                    <option value='DEBIT'>{dictionary().DEBIT}</option>
                    <option value='CREDIT'>{dictionary().CREDIT}</option>
                  </Select>

                  <FormErrorMessage>{errors.account_type?.message}</FormErrorMessage>
                </FormControl>
                <FormControl
                  dir='rtl'
                  id={dictionary().REQUIRED}
                  w={['100%', '70%', '70%', '70%']}
                  display='inline-block'
                  isInvalid={
                    !!errors.amount ||
                    error?.data?.message === 'balance is less than the transactions amount' ||
                    error?.data?.message === 'this ref not found or the balance not enough'
                  }
                >
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {dictionary().AMOUNT} <Reqiured />
                    </>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement height='100%' pointerEvents='none'>
                      {dictionary().PRICING_CURRENCY}
                    </InputLeftElement>
                    <Input
                      dir='ltr'
                      textAlign='right'
                      type='number'
                      {...register('amount', {
                        required: dictionary().REQUIRED,
                        valueAsNumber: true,
                        min: {
                          value: 0,
                          message: dictionary().NO_NEGATIVE_NUMBER,
                        },
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                  {
                    <FormErrorMessage>
                      {error?.data?.message === 'balance is less than the transactions amount' && dictionary().BALANCE_IS_LESS_THAN_TRANSACTION_AMOUNT}
                      {error?.data?.message === 'this ref not found or the balance not enough' && dictionary().ACC_TYPE_NOT_FOUND}
                    </FormErrorMessage>
                  }
                </FormControl>
                {isLoadingCorporate ? (
                  <Spinner />
                ) : (
                  <FormControl
                    dir='rtl'
                    id={action.title === dictionary().TRANSFER_TO_DRIVER ? dictionary().DRIVER : dictionary().NUMBER_PLATE}
                    w={['100%', '70%', '70%', '70%']}
                    isInvalid={!!errors.second_ref_id}
                  >
                    <FormLabel>
                      {action.title === dictionary().TRANSFER_TO_DRIVER ? dictionary().DRIVER : dictionary().NUMBER_PLATE} <Reqiured />
                    </FormLabel>
                    <Select
                      dir='ltr'
                      textAlign='right'
                      {...register('second_ref_id', { valueAsNumber: true, required: dictionary().REQUIRED })}
                      placeholder={action.title === dictionary().TRANSFER_TO_DRIVER ? dictionary().DRIVER : dictionary().NUMBER_PLATE}
                    >
                      {corporate.length > 0 &&
                        corporate?.map(
                          (item: any) =>
                            item.id != id && (
                              <option key={item?.id} value={item?.id}>
                                {item?.name ? item?.name : item?.number_plate}
                              </option>
                            ),
                        )}
                    </Select>
                    <FormErrorMessage>{errors.second_ref_id?.message}</FormErrorMessage>
                  </FormControl>
                )}
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && AddTransfer(getValues() as any)} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TranseferBetweenEntitiesModal
