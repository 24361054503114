import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { FC, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import Reqiured from '../../components/core/reqiured'
import UploadFileInput from '../../components/core/UploadFileInput'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

type Props = {
  updateData?: () => void
}

const AddAds: FC<Props> = ({ updateData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { onSuccess } = useAlert()
  const [previewArUrl, setPreviewArUrl] = useState<null | string>(null)
  const [previewEnUrl, setPreviewEnUrl] = useState<null | string>(null)
  const [sortOrder, setSortOrder] = useState<number>(1)
  const {
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    watch,
  } = useForm({ mode: 'onChange' })

  useEffect(() => {
    if (isOpen) {
      setValue('ar', '')
      setValue('en', '')
      setPreviewArUrl('')
      setPreviewEnUrl('')
      setSortOrder(1)
    }
  }, [isOpen])

  const selectedArFile = watch('ar')
  const selectedEnFile = watch('en')

  const { mutate, isLoading } = useMutation((adsData: any) => fetch('POST', `/banner`, { data: adsData }), {
    onSuccess: () => {
      updateData?.()
      onClose()
      onSuccess()
    },
  })

  const AddAds = (data: any) => {
    const payload = new FormData()
    payload.append('ar', data.ar[0])
    payload.append('en', data.en[0])
    payload.append('sort_order', `${sortOrder}`)
    mutate(payload)
  }

  useEffect(() => {
    if (selectedArFile && selectedArFile[0]) {
      console.log('🚀 ~ useEffect ~ selectedArFile:', selectedArFile)
      const file = selectedArFile[0]
      const url = URL.createObjectURL(file)
      setPreviewArUrl(url)
      return () => URL.revokeObjectURL(url)
    }
  }, [selectedArFile])

  useEffect(() => {
    if (selectedEnFile && selectedEnFile[0]) {
      const file = selectedEnFile[0]
      const url = URL.createObjectURL(file)
      setPreviewEnUrl(url)
      return () => URL.revokeObjectURL(url)
    }
  }, [selectedEnFile])

  const deleteArImage = () => {
    setValue('ar', null)
    setPreviewArUrl(null)
  }
  const deleteEnImage = () => {
    setValue('en', null)
    setPreviewEnUrl(null)
  }

  return (
    <>
      <Button
        data-test='add-ads'
        onClick={onOpen}
        style={{
          height: '40px',
          width: '136px',
          borderRadius: '8px',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '20px',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          background: '#675DCD',
          textDecorationSkipInk: 'none',
        }}
        leftIcon={<img src='/assets/add-icon-white.svg' style={{ marginTop: '4px' }} />}
        color={'#fff'}
        h='48px'
      >
        {dictionary().ADD_NEW}
      </Button>
      <Modal size={'6xl'} initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay bg='#00000044' />
        <ModalContent data-test='add-ads-modal' borderRadius={'15px'} style={{ boxShadow: '0px 4px 15px 0px #00000026' }}>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().UPLOAD_ADS} </ModalHeader>
          <ModalBody pb={6} dir='rtl'>
            <Grid templateColumns='repeat(12, 1fr)' gap={4}>
              <GridItem colSpan={[12, 6]}>
                <FormControl id={dictionary().ADS_EN} isInvalid={!!errors.en}>
                  <FormLabel>
                    {dictionary().ADS_EN} <Reqiured />
                  </FormLabel>
                  <UploadFileInput
                    register={register('en', {
                      required: dictionary().REQUIRED,
                    })}
                    inputId='en-image'
                    previewUrl={previewEnUrl}
                    deleteFile={deleteEnImage}
                  />
                  <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl id={dictionary().ADS_AR} isInvalid={!!errors.ar}>
                  <FormLabel>
                    {dictionary().ADS_AR} <Reqiured />
                  </FormLabel>
                  <UploadFileInput
                    register={register('ar', {
                      required: dictionary().REQUIRED,
                    })}
                    inputId='ar-image'
                    previewUrl={previewArUrl}
                    deleteFile={deleteArImage}
                  />
                  <FormErrorMessage>{errors?.ar?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormLabel>
                  {dictionary().ADS_SORT} <Reqiured />
                </FormLabel>
                <Box
                  border={'1px solid #E9E7E7FF'}
                  p='10px'
                  borderRadius='10px'
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  width={['50%', '25%']}
                  data-test='sort-order'
                >
                  {sortOrder === 1 ? (
                    <img data-test='arrow-down-disabled' src='/assets/arrow_grey.svg' alt='up' style={{ cursor: 'not-allowed' }} />
                  ) : (
                    <img
                      data-test='arrow-down'
                      src='/assets/arrow_black.svg'
                      alt='up'
                      onClick={() => setSortOrder(sortOrder - 1)}
                      style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
                    />
                  )}
                  {sortOrder}
                  <img
                    data-test='arrow-up'
                    src='/assets/arrow_black.svg'
                    alt='down'
                    onClick={() => setSortOrder(sortOrder + 1)}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter dir='rtl'>
            <Button mx={3} onClick={onClose}>
              {dictionary().CANCEL}
            </Button>
            <Button
              pb={1}
              data-test='upload-ads-button'
              colorScheme='purple'
              onClick={async () => (await trigger()) && AddAds(getValues())}
              isLoading={isLoading}
            >
              {dictionary().UPLOAD}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddAds
