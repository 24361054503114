import { Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import PasswordAndConfirmation from '../../components/core/PasswordAndConfirmation'
import PinCodeInput from '../../components/core/PinCodeInput'
import Required from '../../components/core/reqiured'
import SaveButton from '../../components/core/SaveButton'
import { USER_TYPE } from '../../constants'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import { useStore } from '../../store'
import AutoCompleteControl from '../transactions/components/AutoCompleteControl'

const AddDriver: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    watch,
    control,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)

  const { userType } = useStore((state) => state)
  const { push } = useHistory()
  const { onSuccess } = useAlert()
  const [corporatePinReset, setCorporatePinReset] = useState<any>(null)

  const selectedCorporate = useRef(null)
  selectedCorporate.current = watch('corporateId', null)

  useEffect(() => {
    if (selectedCorporate.current !== null && !isNaN(selectedCorporate.current) && (selectedCorporate.current as Array<any>)?.length !== 0) {
      getCorporate()
      setValue('pin_code', '')
    } else {
      setCorporatePinReset(null)
    }
  }, [selectedCorporate.current])

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/drivers', { data: vars }), {
    onSuccess: (): void => {
      push('/drivers')
      onSuccess()
    },
  })

  const { mutate: getCorporate } = useMutation(() => fetch('GET', `/corporates/${selectedCorporate.current}`, {}), {
    onSuccess: (data: any): void => {
      setCorporatePinReset(null)
      setCorporatePinReset(data?.config?.pin_code_digits_number)
    },
  })

  function removeEmptyKeys(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== ''))
  }

  const AddDriver = async (formData: any) => {
    userType === USER_TYPE.ADMIN && (formData.corporateId = +formData?.corporateId[0])
    formData.is_demo = false
    delete formData.confirmedPass
    const data = removeEmptyKeys(formData)
    mutate(data)
  }

  return (
    <Container>
      <Flex ref={headerRef} flexDir='column' className='margin-150'>
        <Header
          title={dictionary().ADD_NEW_DRIVER}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && AddDriver(getValues() as any)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(AddDriver as any)}>
          <Flex className='margin-100' justify='center' align='center' direction='column'>
            {userType === USER_TYPE.ADMIN && (
              <AutoCompleteControl
                control={control}
                errors={errors}
                identifier='corporateId'
                label={dictionary().CORPORATE}
                route='/corporates'
                displayFormatter={(option: any) => option?.name}
                dataTest={dictionary().CORPORATE}
              />
            )}
            {selectedCorporate.current !== null && (
              <PinCodeInput
                error={error}
                errors={errors}
                register={register}
                setValue={setValue}
                getValues={getValues}
                width={['100%', '70%', '70%', '70%']}
                pinLength={corporatePinReset}
              />
            )}

            <FormControl
              id={dictionary().USERNAME}
              w={['100%', '70%', '70%', '70%']}
              isInvalid={
                !!errors.username ||
                (error as any)?.data?.message === 'Username already exists' ||
                (error as any)?.data?.message?.[0] === 'username must be longer than or equal to 4 characters' ||
                (error as any)?.data?.message === `duplicate value ${getValues()?.username} for key username`
              }
            >
              <FormLabel>{dictionary().USERNAME}</FormLabel>
              <Input
                type='string'
                data-test={dictionary().USERNAME}
                {...register('username', {
                  required: false,
                  pattern: {
                    value: /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+$/,
                    message: dictionary().USERNAME_ALPHA_NUMERIC_SYMBOL_ONLY,
                  },
                })}
              />
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === 'Username already exists' && dictionary().THIS_USER_NAME_IS_ALREADY_TAKEN}
                {(error as any)?.data?.message?.[0] === 'username must be longer than or equal to 4 characters' && dictionary().ENTER_VALID_USERNAME}
                {(error as any)?.data?.message === `duplicate value ${getValues()?.username} for key username` && dictionary().THIS_USER_NAME_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <PasswordAndConfirmation
              isPasswordRequired={!!watch('username')}
              error={error}
              register={register}
              errors={errors}
              watch={watch}
              width={['100%', '70%', '70%', '70%']}
            />
            <FormControl id={dictionary().NAME} w={['100%', '70%', '70%', '70%']} isInvalid={!!errors.name}>
              <FormLabel>
                {dictionary().NAME} <Required />
              </FormLabel>
              <Input
                type='string'
                data-test={dictionary().NAME}
                {...register('name', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: /^[a-zA-Z0-9\s!@#$%^&*()_+=\-.,\u0600-\u06FF]+$/,
                    message: dictionary().NAME_ALPHA_NUMERIC_SPECIAL_CHAR_ONLY,
                  },
                })}
              />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DRIVER_PHONE}
              w={['100%', '70%', '70%', '70%']}
              isInvalid={
                !!errors.mobileNumber ||
                (error as any)?.data?.message === 'Mobile number already exists' ||
                (error as any)?.data?.message === `duplicate value ${getValues().mobileNumber} for key mobile_number`
              }
            >
              <FormLabel>{dictionary().DRIVER_PHONE}</FormLabel>
              <Input
                type='text'
                data-test={dictionary().DRIVER_PHONE}
                {...register('mobileNumber', {
                  required: false,
                  pattern: {
                    value: /^01[0125][0-9]{8}$/,
                    message: dictionary().INVALID_PHONE,
                  },
                })}
              />
              <FormErrorMessage>{errors.mobileNumber?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === 'Mobile number already exists' && dictionary().THIS_PHONE_NUMBER_IS_ALREADY_TAKEN}
                {(error as any)?.data?.message === `duplicate value ${getValues().mobileNumber} for key mobile_number` &&
                  dictionary().THIS_PHONE_NUMBER_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DRIVER_CODE}
              w={['100%', '70%', '70%', '70%']}
              isInvalid={!!errors.code || (error as any)?.data?.message === 'Code already exists within same corporate !'}
            >
              <FormLabel>{dictionary().DRIVER_CODE}</FormLabel>
              <Input
                data-test={dictionary().DRIVER_CODE}
                type='number'
                {...register('code', {
                  required: false,
                  pattern: {
                    value: /^[1-9]\d*$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_NUMBERS,
                  },
                })}
              />
              <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === 'Code already exists within same corporate !' && dictionary().CODE_MUST_UNIQUE}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddDriver
