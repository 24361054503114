import { Box, Container, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import Header from '../../components/core/Header'
import Pagination from '../../components/core/Pagination'

import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { useFilterStore } from '../../stores/filterStore'
import AddAds from './add'
import AdsTable from './AdsTable'

const headers = [
  {
    id: 'adsId',
    key: 'sort_order',
    name: dictionary().ID,
    type: 'text',
  },

  {
    id: 'adsImage',
    key: 'advertisement',
    name: dictionary().IMAGE,
    type: 'image',
  },
  {
    id: 'adsActions',
    key: 'adsActions',
    name: '',
    type: 'modal',
  },
]

const Ads: React.FC = () => {
  const { page } = useFilterStore()
  const [isUpdated, setIsUpdated] = useState(false)
  const raisedProps = { borderRadius: 'lg', boxShadow: '1px', borderWidth: '1px', bg: 'white' }
  const [adsData, setAdsData] = useState<any[]>([])

  const updateData = () => setIsUpdated(!isUpdated)

  const { isLoading, mutate, data } = useMutation(() => fetch('GET', `/banner?page=${page}`), {
    onSuccess: (res) => setAdsData(res?.data),
  })

  useEffect(() => {
    mutate()
  }, [isUpdated])

  return (
    <Container maxW='100%' width='100%' h='100vh' padding={5}>
      <Header title={dictionary().CAR_SERVICES_TRANSACTION} action={<AddAds updateData={updateData} />} />
      <Grid templateColumns='repeat(12, 1fr)' alignItems={'center'}>
        <GridItem colSpan={[12]} display='flex' alignItems='center' justifyContent='flex-end'>
          <Pagination totalCount={data?.total || 0} pageSize={data?.page_size || 30} />
        </GridItem>
        <GridItem colSpan={[12]}>
          <Box maxH={'80vh'} overflowY='auto' {...raisedProps} id='ads-table'>
            {isLoading ? (
              <Flex w='100%' h='70vh' align='center' justify='center'>
                <Text fontSize='2xl'>
                  {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().ADS}
                </Text>
              </Flex>
            ) : adsData?.length === 0 ? (
              <Flex w='100%' h='70vh' align='center' justify='center'>
                <Text fontSize='2xl'>
                  {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {dictionary().ADS}
                </Text>
              </Flex>
            ) : (
              <AdsTable adsData={adsData} updateData={updateData} headers={headers} setAdsData={setAdsData} />
            )}
          </Box>
        </GridItem>
      </Grid>
    </Container>
  )
}

export default Ads
