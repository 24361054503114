import React, { FC } from 'react'

interface Props {
  images: { en: string; ar: string }
}

const AdsImages: FC<Props> = ({ images }) => {
  return (
    <div style={{ display: 'flex', gap: '10px', flexDirection: 'column', alignItems: 'center' }}>
      <img data-test='ads-en-image' src={images.en} alt='ar' style={{ width: '600px', height: '82px', borderRadius: '10px' }} />
      <img data-test='ads-ar-image' src={images.ar} alt='ar' style={{ width: '600px', height: '82px', borderRadius: '10px' }} />
    </div>
  )
}

export default AdsImages
