import { Flex, Text, TextProps, FlexProps, Skeleton } from '@chakra-ui/react'
import { FC } from 'react'
type Props = {
  label: string
  value: string
  containerProps?: FlexProps
  valueColor?: TextProps['color']
  isLoading?: boolean
}
const BulkUploadValidationInfoText: FC<Props> = ({ label, value, containerProps, valueColor, isLoading = false }) => {
  return (
    <Flex style={{ direction: 'rtl' }} justifyContent='center' alignItems={'center'} ml={'20px'} {...containerProps}>
      <Text letterSpacing={'1px'} fontSize={'15px'} fontWeight={'600'} mt={0} whiteSpace='nowrap'>
        {label}
      </Text>
      {isLoading ? (
        <Skeleton height={'20px'} width={'18px'} mr={1} />
      ) : (
        <Text letterSpacing={'1px'} fontSize={'15px'} fontWeight={'600'} mt={0} color={valueColor && valueColor} mr={1}>
          {value}
        </Text>
      )}
    </Flex>
  )
}

export default BulkUploadValidationInfoText
