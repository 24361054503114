import { FC } from 'react'
import { Button, Spinner } from '@chakra-ui/react'
import { useStore } from '../../../store'
import { canDownload } from '../../../constants/canDownload'
import { useMutation } from 'react-query'
import fetch from '../../../helpers/fetch'

type Props = {
  text: string
  endPoint: string
  fileName?: string
}

const DownloadButton: FC<Props> = ({ text, endPoint, fileName = 'file' }) => {
  const { role } = useStore()

  //----------------------- use mutation hook to download a file from endPoint ------------------------------
  const downloadFile = async () => {
    const blob = (await fetch('GET', endPoint, {}, undefined, 'blob')) as Blob
    const urlObject = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = urlObject
    link.download = `${fileName}.csv`
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(urlObject)
  }

  const { isLoading, mutate } = useMutation(downloadFile, {
    onSuccess: (): void => {},
    onError: (err: any): void => {
      console.error('--------------------->Error downloading file:', err)
    },
  })
  //---------------------------------------------------------------------------------------------------------

  return (
    <>
      {canDownload(role) && (
        <Button
          backgroundColor={'#EEF2F6'}
          borderRadius={'5px'}
          padding={'10px'}
          fontWeight={'500'}
          fontSize={'12px'}
          onClick={() => mutate()}
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : text}
        </Button>
      )}
    </>
  )
}

export default DownloadButton
