import React, { ReactElement } from 'react'
import { FiMenu } from 'react-icons/fi'

import { Box, Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, Grid, GridItem, Hide, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { CASHING_KEY, USER_TYPE } from '../../constants'
import Sidebar from '../layout/Sidebar'
import ActionsButton from './ActionsButton'
import AddNewButton from './AddNewButton'

interface Props {
  title: any
  subTitle?: string
  id?: number
  to?: string
  actions?: { action?: string; title?: string; apiRoute?: string; redirectRoute?: string }[]
  isLoading?: boolean
  redirect?: (val: string) => void
  action?: ReactElement<any, any> | React.FC<any>
  cashingKey?: string
  userType?: string
  loggedUser?: any
  userTitle?: string
  handleIsBalancesChange?: () => void
  handleIsDriverAssigned?: () => void
  handleIsCorporateSPUnbilled?: () => void
  handleIsCorporateAccessSP?: () => void
  handleIsBalanseChanged?: () => void
  data?: any
  addNew?: boolean
}
const Header: React.FC<Props> = (props) => {
  const { pathname } = useLocation()
  const {
    id,
    to,
    action,
    actions,
    redirect,
    cashingKey,
    userType,
    loggedUser,
    userTitle,
    handleIsBalancesChange,
    handleIsDriverAssigned,
    handleIsCorporateSPUnbilled,
    handleIsCorporateAccessSP,
    handleIsBalanseChanged,
    data,
    addNew,
    title,
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Grid w='100%' mt={['65px', '75px', '75px', 0]} position='relative'>
      <Box mt={'10px'} width={'10px'} position={'absolute'} right='5px'>
        <Hide below='769px'>
          <Box mt={'0px'} width={'10px'} ml={'10px'} cursor='pointer'>
            <FiMenu fontSize={'20px'} color={'#718096'} onClick={onOpen} data-test='sidebar-open-icon' />
          </Box>
          <Drawer id='headerModal' isOpen={isOpen} placement='right' onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <div>
                <Sidebar />
              </div>
            </DrawerContent>
          </Drawer>
        </Hide>
      </Box>

      <GridItem colSpan={[6, 6]} display='flex' justifyContent='space-between' width='100%' mb={'15px'}>
        <div style={{ width: '151px' }}></div>
        {pathname === '/duplicate-transactions' && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Text fontSize='2xl' color='blue.500'>
              {title}
            </Text>
          </div>
        )}
        {loggedUser && (
          <>
            {loggedUser.isLoading ? (
              <Spinner />
            ) : (
              <Text fontSize='2xl' color='blue.500'>
                {userTitle}
              </Text>
            )}
          </>
        )}
        {cashingKey === CASHING_KEY.GET_FUEL_TRANSACTIONS && userType === USER_TYPE.ADMIN ? to && <AddNewButton to={to} /> : <></>}
        {cashingKey !== CASHING_KEY.GET_TOLL_TRANSACTIONS &&
        cashingKey !== CASHING_KEY.GET_STORE_TRANSACTIONS &&
        cashingKey !== CASHING_KEY.GET_FUEL_TRANSACTIONS_FOR_DATA_ENTRY &&
        cashingKey !== CASHING_KEY.GET_FUEL_TRANSACTIONS ? (
          to && addNew !== false && <AddNewButton to={to} />
        ) : (
          <></>
        )}
        {actions && (
          <ActionsButton
            id={id}
            redirect={redirect}
            actions={actions}
            handleIsBalancesChange={handleIsBalancesChange}
            handleIsDriverAssigned={handleIsDriverAssigned}
            handleIsCorporateSPUnbilled={handleIsCorporateSPUnbilled}
            handleIsCorporateAccessSP={handleIsCorporateAccessSP}
            handleIsBalanseChanged={handleIsBalanseChanged}
            data={data}
          />
        )}

        {action ? action : <></>}
      </GridItem>
    </Grid>
  )
}

export default Header
