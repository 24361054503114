import { Flex, Text, TextProps } from '@chakra-ui/react'
import { FC } from 'react'
import ErrorFieldIcon from '../../../assets/ErrorFieldIcon.png'

type Props = {
  message?: string
  textProps?: TextProps
}
const BulkUploadErrorMessage: FC<Props> = ({ message = 'error', textProps = { fontSize: '14px', fontWeight: '500', color: '#B42318' } }) => {
  return (
    <Flex style={{ direction: 'rtl' }} alignItems={'center'} mt={3}>
      <img src={ErrorFieldIcon} width='16px' height='16px' style={{ marginLeft: '10px' }} />
      <Text {...textProps}>{message}</Text>
    </Flex>
  )
}

export default BulkUploadErrorMessage
