import { FC } from 'react'
import { Button, Flex, ModalFooter, Spinner } from '@chakra-ui/react'
import dictionary from '../../../dictionary'

import DownloadIcon from '../../../assets/Download.png'
import UploadIcon from '../../../assets/Upload.png'
import { FileUploadData } from './BulkUploadType'
type Props = {
  close: () => void
  revalidateFileUploadMutatation: { revalidateFileUploadMutate: () => void; revalidateFileUploadIsLoading: boolean }
  downloadCorrectedFileMutatation: {
    downloadCorrectedFileMutate: () => void
    downloadCorrectedFileIsLoading: boolean
  }
  finalUploadMutatation: { finalUploadMutate: () => void; finalUploadIsLoading: boolean }
  fileUploadData: FileUploadData
}
const ValidationModalFooter: FC<Props> = ({
  close,
  revalidateFileUploadMutatation,
  downloadCorrectedFileMutatation,
  finalUploadMutatation,
  fileUploadData,
}) => {
  return (
    <ModalFooter display='flex' justifyContent='space-between' alignItems='center'>
      <Flex style={{ direction: 'rtl' }}>
        <Button
          ml={3}
          backgroundColor={'white'}
          borderWidth={'1px'}
          borderColor={'#D0D5DD'}
          borderRadius={'8px'}
          padding={'10px'}
          fontWeight={'600'}
          fontSize={'14px'}
          letterSpacing={'1.5px'}
          color={'#344054'}
          onClick={close}
        >
          {dictionary().CANCEL}
        </Button>

        <Button
          color={'white'}
          backgroundColor={'#6941C6'}
          fontWeight={'600'}
          fontSize={'14px'}
          letterSpacing={'1.5px'}
          disabled={revalidateFileUploadMutatation.revalidateFileUploadIsLoading || fileUploadData?.invalid_fields_count <= 0}
          onClick={() => revalidateFileUploadMutatation.revalidateFileUploadMutate()}
        >
          {revalidateFileUploadMutatation.revalidateFileUploadIsLoading && <Spinner marginLeft={'10px'} />}
          إعادة التحقق
        </Button>
      </Flex>

      <Flex style={{ direction: 'rtl' }}>
        <Button
          backgroundColor={'white'}
          borderWidth={'1px'}
          borderColor={'#D0D5DD'}
          borderRadius={'8px'}
          padding={'10px'}
          fontWeight={'600'}
          fontSize={'14px'}
          letterSpacing={'1.5px'}
          color={'#344054'}
          disabled={finalUploadMutatation.finalUploadIsLoading || fileUploadData?.invalid_fields_count > 0}
          onClick={() => finalUploadMutatation.finalUploadMutate()}
        >
          {finalUploadMutatation.finalUploadIsLoading && <Spinner marginLeft={'10px'} />}
          <img src={UploadIcon} alt='Download icon' style={{ marginLeft: '8px', width: '16px', height: '15px' }} />
          تحميل
        </Button>

        <Button
          backgroundColor={'white'}
          borderWidth={'1px'}
          borderColor={'#D0D5DD'}
          borderRadius={'8px'}
          padding={'10px'}
          fontWeight={'600'}
          fontSize={'14px'}
          letterSpacing={'1.5px'}
          color={'#344054'}
          mr={3}
          disabled={downloadCorrectedFileMutatation.downloadCorrectedFileIsLoading}
          onClick={() => downloadCorrectedFileMutatation.downloadCorrectedFileMutate()}
        >
          {downloadCorrectedFileMutatation.downloadCorrectedFileIsLoading && <Spinner marginLeft={'10px'} />}
          <img src={DownloadIcon} alt='Download icon' style={{ marginLeft: '8px', width: '16px', height: '15px' }} />
          تنزيل
        </Button>
      </Flex>
    </ModalFooter>
  )
}

export default ValidationModalFooter
