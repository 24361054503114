import { Flex, GridItem, ModalBody, Text } from '@chakra-ui/react'

import CheckMark from '../../../assets/CheckMark.png'

const SuccessfulUploadModal = () => {
  return (
    <ModalBody pt={10} pb={14}>
      <GridItem style={{ direction: 'rtl' }} mb={1}>
        <Flex alignItems={'center'} justifyContent={'center'} gap='15px' direction='column'>
          <img src={CheckMark} alt='Successful Upload Check Mark' style={{ width: '109px', height: '109px' }} />
          <Text fontWeight={'600'} fontSize={'16px'} color={'#232B4B'} letterSpacing={'1.5px'}>
            تم تحميل الملف بنجاح
          </Text>
        </Flex>
      </GridItem>
    </ModalBody>
  )
}

export default SuccessfulUploadModal
