import Actions from '../Actions'

export enum MODAL_STATUS {
  INIT = 'INIT',
  ERROR_WARNING = 'ERROR_WARNING',
  VALIDATION = 'VALIDATION',
  REVALIDATION = 'REVALIDATION',
  UPLOADED_SUCCESS = 'UPLOADED_SUCCESS',
}

export type FileUploadData = {
  data: Record[]
  records_count: number
  invalid_records_count: number
  invalid_fields_count: number
}

export type Record = {
  id: number
  data: VehicleORDriverData
}

export type VehicleORDriverData = {
  [key: string]: Cell
}

export type Cell = {
  value: string
  error: string
}

export type Actions = {
  type: string
  submitUploadEndpoint: string
  downloadCorrectedEndpoint: string
  revalidateEndpoint: string
  initialFileUploadEndpoint: string
  title: string
  apiHeader: string
  fileName: string
}

export type EditedData = {
  id: number
  [key: string]: string | number
}[]

export type FileUploadForm = {
  file_asset: FileList | string | any
}
