import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import dictionary from '../../dictionary'
import Reqiured from './reqiured'

interface Props {
  register: any
  getValues?: any
  setValue?: any
  width?: any
  minHight?: any
  errors?: any
  error?: any
  pinLength: number
}

const PinCodeInput: React.FC<Props> = ({ register, errors, width, minHight, error, pinLength }) => {
  return (
    <FormControl
      id={dictionary().PIN}
      w={width}
      minH={minHight}
      display='block'
      isInvalid={!!errors?.pin_code || error?.data?.message === 'This pin code already exists within the same corporate/corporate group !'}
    >
      <FormLabel mb='10px'>
        {dictionary().PIN} <Reqiured />
      </FormLabel>
      <Input
        type='number'
        data-test={dictionary().PIN}
        disabled={pinLength === null}
        {...register('pin_code', {
          required: dictionary().REQUIRED,
          validate: (value: any) => {
            return value.length === pinLength ? true : dictionary().PIN_CODE_WRONG_LENGTH + pinLength
          },
        })}
      />
      <FormErrorMessage>{errors?.pin_code?.message}</FormErrorMessage>
      <FormErrorMessage>
        {error?.data?.message === 'This pin code already exists within the same corporate/corporate group !' && dictionary().PIN_CODE_ALREADY_EXISTS}
      </FormErrorMessage>
    </FormControl>
  )
}

export default PinCodeInput
