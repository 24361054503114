import { Box, Input, Td, Text, Tooltip } from '@chakra-ui/react'
import { FC, useState } from 'react'
import EditingIcon from '../../../assets/EditingIcon.png'
import ErrorFieldIcon from '../../../assets/ErrorFieldIcon.png'
import BulkUploadToolTip from './BulkUploadToolTip'
import { EditedData, Record } from './BulkUploadType'

type Props = {
  header: string
  record: Record
  editedData: EditedData
  handleInputChange: (value: string, record: Record, header: string) => void
}

const ValidationModalCell: FC<Props> = ({ header, record, editedData, handleInputChange }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const currentCellValue = editedData.find((item) => item.id === record.id)?.[header] ?? record.data[header]?.value ?? ''

  return (
    <Td key={header} justifyContent='center' alignItems='center' p={2}>
      <Box display='flex' justifyContent='center' alignItems='center' minHeight={'45px'}>
        {record.data[header]?.error && (
          <Tooltip
            maxWidth='431px'
            backgroundColor='white'
            label={<BulkUploadToolTip text={record.data[header]?.error} />}
            placement='top'
            fontSize='md'
            borderRadius='8px'
          >
            <img
              onClick={() => setIsEditing((prev) => !prev)}
              src={record.data[header]?.value !== currentCellValue ? EditingIcon : ErrorFieldIcon}
              width='16px'
              height='16px'
              style={{ cursor: 'pointer', marginLeft: '10px' }}
            />
          </Tooltip>
        )}

        {isEditing ? (
          <Input
            autoFocus
            value={currentCellValue}
            onChange={(e) => handleInputChange(e.target.value, record, header)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setIsEditing(false)
              }
            }}
            margin='0'
            fontSize='12px'
            fontWeight='500'
            color='#000000'
          />
        ) : (
          <Text
            fontSize='12px'
            fontWeight='500'
            wordBreak={currentCellValue.toString().length > 10 ? 'break-word' : 'normal'}
            color={
              record.data[header]?.error && record.data[header]?.value === currentCellValue
                ? '#B42318'
                : record.data[header]?.error && record.data[header]?.value !== currentCellValue
                ? '#E99F27'
                : '#000000'
            }
            onClick={record.data[header]?.error ? () => setIsEditing(true) : () => {}}
            cursor={record.data[header]?.error ? 'pointer' : 'auto'}
          >
            {currentCellValue || ''}
          </Text>
        )}
      </Box>
    </Td>
  )
}

export default ValidationModalCell
