import { MenuItem, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import moment from 'moment'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import fetch from '../../../helpers/fetch'
import { useDetailsStore } from '../../../stores/detailsStore'
import { Actions, EditedData, FileUploadData, FileUploadForm, MODAL_STATUS } from './BulkUploadType'
import ErrorWarningModal from './ErrorWarningModal'
import InitialModal from './InitialModal'
import SuccessfulUploadModal from './SuccessfulUploadModal'
import ValidationModal from './ValidationModal'

type Props = {
  action: Actions
}

const BulkUpload: React.FC<Props> = ({ action }) => {
  const [modalStatus, setModalStatus] = useState<MODAL_STATUS>(MODAL_STATUS.INIT)
  const [headers, setHeaders] = useState<string[]>([])
  const [editedData, setEditedData] = useState<EditedData>([])
  const [numberOfSkeletonRows, setNumberOfSkeletonRows] = useState<number | undefined>()
  const { details } = useDetailsStore()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<FileUploadForm>({ mode: 'onChange' })

  //-------------------------use mutation hook to upload file for the first time-----------------------------
  const {
    mutate: initialFileUploadMutate,
    isLoading: initialFileUploadIsLoading,
    data: initialFileUploadData,
    isError: initialFileUploadIsError,
    error: initialFileUploadError,
    reset: initialFileUploadReset,
  } = useMutation<FileUploadData | undefined>(
    async () => {
      const formData = new FormData()
      const files = getValues()?.file_asset
      for (const file of files) {
        formData.append('file_asset', file)
      }
      return fetch('POST', `${action?.initialFileUploadEndpoint}${id}`, { data: formData }, undefined, 'json')
    },
    {
      onSuccess: (data: FileUploadData | undefined) => {
        console.log('File uploaded successfully:', data)
        setModalStatus(MODAL_STATUS.ERROR_WARNING)
        const dataForHeaders = data?.data[0]?.data
        setHeaders(Object.keys(dataForHeaders!))
        setNumberOfSkeletonRows(data?.data?.length)
      },
      onError: (error: any) => {
        console.error('--------------------->Error uploading file:', error)
      },
    },
  )
  const onSubmit = async () => {
    const isValid = await trigger()
    if (isValid) {
      initialFileUploadMutate()
    }
  }
  //---------------------------------------------------------------------------------------------------------
  //--------------------------------- use mutation hook to reValidate data ----------------------------------
  const {
    mutate: revalidateFileUploadMutate,
    isLoading: revalidateFileUploadIsLoading,
    data: revalidateFileUploadData,
  } = useMutation<FileUploadData | undefined>(
    async () => {
      return fetch('POST', `${action?.revalidateEndpoint}${id}`, { data: editedData }, undefined, 'json')
    },
    {
      onSuccess: (data: FileUploadData | undefined) => {
        setModalStatus(MODAL_STATUS.REVALIDATION)
        setNumberOfSkeletonRows(data?.data?.length)
      },
      onError: (error: any) => {
        console.error('--------------------->Error revalidating file:', error)
      },
    },
  )
  //---------------------------------------------------------------------------------------------------------

  //------------------------------ use mutation hook to download Corrected data file ------------------------
  const downloadFile = async () => {
    const blob = (await fetch('GET', `${action?.downloadCorrectedEndpoint}${id}`, {}, undefined, 'blob')) as Blob
    const urlObject = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = urlObject
    link.download = `${action.fileName} ${details?.name} ${moment().format('D-M-YYYY h:mm A')}.csv` // File name
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(urlObject)
  }

  const { mutate: downloadCorrectedFileMutate, isLoading: downloadCorrectedFileIsLoading } = useMutation(downloadFile, {
    onSuccess: (): void => {},
    onError: (error: any): void => {
      console.error('--------------------->Error downloading file:', error)
    },
  })
  //---------------------------------------------------------------------------------------------------------

  //----------------------------- use mutation hook to do a final upload data -------------------------------
  const {
    mutate: finalUploadMutate,
    isLoading: finalUploadIsLoading,
    data: finalUploadData,
  } = useMutation<FileUploadData | undefined>(
    async () => {
      return fetch('POST', `${action?.submitUploadEndpoint}${id}`, {}, undefined, 'json')
    },
    {
      onSuccess: (data: FileUploadData | undefined) => {
        setModalStatus(MODAL_STATUS.UPLOADED_SUCCESS)
      },
      onError: (error: any) => {
        console.error('--------------------->Error revalidating file:', error)
      },
    },
  )
  //---------------------------------------------------------------------------------------------------------

  const close = () => {
    onClose()
    setModalStatus(MODAL_STATUS.INIT)
    setEditedData([])
    setHeaders([])
    setValue('file_asset', '')
    initialFileUploadReset()
  }

  return (
    <>
      <MenuItem onClick={onOpen}>{action?.title}</MenuItem>
      <Modal isCentered initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={close}>
        <ModalOverlay />
        <ModalContent maxWidth='95%' maxHeight={'80%'} minWidth={'400px'} width='fit-content' borderRadius='15px'>
          {modalStatus === MODAL_STATUS.INIT && (
            <InitialModal
              close={close}
              action={action}
              initialFileUploadMutation={{
                initialFileUploadIsLoading: initialFileUploadIsLoading,
                initialFileUploadIsError: initialFileUploadIsError,
                initialFileUploadError: initialFileUploadError,
                initialFileUploadReset: initialFileUploadReset,
              }}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
            />
          )}
          {modalStatus === MODAL_STATUS.ERROR_WARNING && (
            <ErrorWarningModal setModalStatus={setModalStatus} close={close} initialFileUploadData={initialFileUploadData!} />
          )}
          {(modalStatus === MODAL_STATUS.VALIDATION || modalStatus === MODAL_STATUS.REVALIDATION) && (
            <ValidationModal
              headers={headers}
              fileUploadData={modalStatus === MODAL_STATUS.REVALIDATION ? revalidateFileUploadData : initialFileUploadData}
              close={close}
              revalidateFileUploadMutatation={{
                revalidateFileUploadMutate: revalidateFileUploadMutate,
                revalidateFileUploadIsLoading: revalidateFileUploadIsLoading,
              }}
              downloadCorrectedFileMutatation={{
                downloadCorrectedFileMutate: downloadCorrectedFileMutate,
                downloadCorrectedFileIsLoading: downloadCorrectedFileIsLoading,
              }}
              finalUploadMutatation={{
                finalUploadMutate: finalUploadMutate,
                finalUploadIsLoading: finalUploadIsLoading,
              }}
              editedData={editedData}
              setEditedData={setEditedData}
              numberOfSkeletonRows={numberOfSkeletonRows ?? 1}
            />
          )}

          {modalStatus === MODAL_STATUS.UPLOADED_SUCCESS && <SuccessfulUploadModal />}
        </ModalContent>
      </Modal>
    </>
  )
}

export default BulkUpload
