import { Button, Flex, GridItem, ModalBody, ModalFooter, Text } from '@chakra-ui/react'
import { FC } from 'react'
import dictionary from '../../../dictionary'
import redWarning from '../../../assets/redWarning.png'
import { FileUploadData, MODAL_STATUS } from './BulkUploadType'

type Props = {
  setModalStatus: (state: MODAL_STATUS) => void
  close: () => void
  initialFileUploadData: FileUploadData
}

const ErrorWarningModal: FC<Props> = ({ setModalStatus, close, initialFileUploadData }) => {
  return (
    <>
      <ModalBody pb={6}>
        <GridItem style={{ direction: 'rtl' }} mb={1}>
          <Flex style={{ direction: 'rtl' }} justifyContent='center' alignItems={'center'} flexDirection={'column'} paddingTop={'25px'}>
            <img src={redWarning} width={71} height={66} style={{ marginBottom: '15px' }} />
            <Text letterSpacing={'1.5px'} fontWeight={'600'} fontSize={'16px'} color={'#232B4B'}>
              {dictionary().THERE_ARE_ERRORS_IN_THE_FILE}
            </Text>
            <Flex style={{ direction: 'rtl' }} justifyContent='center' alignItems={'center'} color={'#232B4B'}>
              <Text letterSpacing={'1.5px'} fontWeight={'600'} fontSize={'16px'} m={0} p={0}>
                {dictionary().NUMBER_OF_RECORDS_WEITH_ERRORS}
              </Text>
              <Text fontWeight={'600'} fontSize={'16px'} m={0} p={0} color={'#B42318'} mr={1}>
                {initialFileUploadData?.invalid_records_count}
              </Text>
            </Flex>
          </Flex>
        </GridItem>
      </ModalBody>

      <ModalFooter display='flex' justifyContent='center' alignItems='center' flexDirection={'column'}>
        <Button
          backgroundColor={'white'}
          borderWidth={'1px'}
          borderColor={'#D0D5DD'}
          borderRadius={'8px'}
          padding={'10px'}
          fontWeight={'600'}
          fontSize={'14px'}
          letterSpacing={'1.5px'}
          color={'#344054'}
          mb={3}
          onClick={() => {
            setModalStatus(MODAL_STATUS.VALIDATION)
          }}
        >
          {dictionary().DISPLAY_ERRORS}
        </Button>

        <Button color={'#344054'} backgroundColor={'white'} fontWeight={'600'} fontSize={'14px'} letterSpacing={'1.5px'} onClick={close}>
          {dictionary().CANCEL}
        </Button>
      </ModalFooter>
    </>
  )
}

export default ErrorWarningModal
