import { Flex, Text } from '@chakra-ui/react'
import ErrorFieldIcon from '../../../assets/ErrorFieldIcon.png'

const BulkUploadToolTip = ({ text }: { text: string }) => {
  return (
    <Flex style={{ direction: 'rtl' }} padding={'5px'} justifyContent='center' alignItems={'flex-start'}>
      <img src={ErrorFieldIcon} style={{ marginLeft: '5px', width: '16px', height: '16px', marginTop: '5px' }} />
      <Text style={{ direction: 'rtl' }} color={'#1B202B'} fontSize={'14px'} fontWeight={'500'}>
        {text}
      </Text>
    </Flex>
  )
}

export default BulkUploadToolTip
