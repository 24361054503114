import { Button, Spinner } from '@chakra-ui/react'
import format from 'date-fns/format'
import moment from 'moment'
import * as Papa from 'papaparse'
import { ParsedQuery } from 'query-string'
import { path } from 'ramda'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import * as XLSX from 'xlsx-js-style'
import { canDownload } from '../../constants/canDownload'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { formatDecimal } from '../../helpers/formatDecimal'
import { createVariablesFromQueryString } from '../../helpers/queryStringVariables'
import { useStore } from '../../store'
import { useFilterStore } from '../../stores/filterStore'

interface Props {
  PAGE_SIZE: any
  setPAGE_SIZE: any
  querystringToVariables?: (query: ParsedQuery<string | number | boolean>) => Record<string, unknown>
  restRoute?: any
  vehicleSubscriptionData?: any
  renewalDate?: any
  headers?: any
  query: any
  isUpload: boolean
  apiHeader?: any
  accounting?: boolean
}
declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
  interface UnparseConfig {
    download?: boolean
    encoding?: string
    columns?: any
  }
}

const CheckValidity = (row: any) => {
  if (['VARIANCE_REFUND', 'DUPLICATE_REFUND'].includes(row?.correction_status)) {
    return '-'
  }
  if (row?.is_invalid) {
    return dictionary().NOTVALID
  } else if (row.distance_traveled_errors === 'FIRST_TRANSACTION') {
    return dictionary().FIRST_TRANSACTION
  } else {
    return dictionary().VALID
  }
}

const getAccount = (item: any, type: string) => {
  const account = item.ledger_accounts.filter((accountItem: any) => accountItem.account_type == type)
  return account[0]?.balance
}

const getCorporateGroupName = (item: any, corporateGroups: any) => {
  const corporateGroup = corporateGroups?.data?.data?.find((corpotateGroup: any) => corpotateGroup.id === item.corporateGroupId)
  return item?.corporateGroupId !== null ? corporateGroup?.name : '-'
}

const getFileName = (pathname: string, type: string) => {
  const today = new Date()
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
  const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds()
  return type === 'CSV' ? `${pathname}_${date}_${time}.csv` : `${pathname}_${date}_${time}.xlsx`
}

const getDistanceTraveledErrors = (value: any, item: any) => {
  let distanceError = '-'
  if (['VARIANCE_REFUND', 'DUPLICATE_REFUND'].includes(item?.correction_status)) {
    return distanceError
  }
  if (value === 'INVALID_ODOMETER_READING') {
    distanceError = dictionary().INVALID_ODOMETER_READING
  } else if (value === 'LOWER_THAN_PREVIOUS_ODOMETER_READING') {
    distanceError = dictionary().INVALID_ODOMETER_READING
  } else if (value === 'SAME_PREVIOUS_ODOMETER') {
    distanceError = dictionary().SAME_PREVIOUS_ODOMETER
  } else if (value === 'DISTANCE_MORE_THAN_MAX') {
    distanceError = dictionary().DISTANCE_MORE_THAN_MAX
  } else if (value === 'DISTANCE_LESS_THAN_MIN') {
    distanceError = dictionary().DISTANCE_LESS_THAN_MIN
  } else if (value === 'AFTER_NEGATIVE') {
    distanceError = dictionary().AFTER_NEGATIVE
  } else if (value === 'NEGATIVE_DISTANCE') {
    distanceError = dictionary().NEGATIVE_DISTANCE
  } else if (value === 'FIRST_TRANSACTION') {
    distanceError = dictionary().FIRST_TRANSACTION
  }
  return distanceError
}

const getStatusValue = (value: any, status: string | null, item: any) => {
  if (['VARIANCE_REFUND', 'DUPLICATE_REFUND'].includes(item?.correction_status)) {
    return '-'
  }
  if (value == null && (status === null || status === 'NULL')) {
    return dictionary().PENDING
  } else if (status === 'VALID' || (status === null && value !== null)) {
    return value === 'VALID' ? dictionary().CONFIRMED : value
  } else if (status === 'NULL' || status === null) {
    return dictionary().PENDING
  } else if (status === 'INVALID' || status === 'NOT_READABLE' || value == 0) {
    return dictionary().NOT_READABLE
  } else if (status === 'MISSING') {
    return dictionary().NO_IMG
  }
}

const XlsxExport = (values: any, pathname: string) => {
  const wb = XLSX.utils.book_new()
  const fristRow = [
    {
      v: dictionary().CONSUMTION_RATIO,
      t: 's',
      s: { alignment: { horizontal: 'center' }, font: { bold: true, sz: '12', italic: true, color: { rgb: '000000' } }, fill: { fgColor: { rgb: 'ed7d31' } } },
    },
    {},
    {},
    {},
    {},
    {
      v: dictionary().PAYMENT_DETAILS,
      t: 's',
      s: { alignment: { horizontal: 'center' }, font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '538234' } } },
    },
    {},
    {},
    {
      v: dictionary().TRANSACTION_DETAILS,
      t: 's',
      s: { alignment: { horizontal: 'center' }, font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '000000' } } },
    },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {
      v: dictionary().READINGS,
      t: 's',
      s: { alignment: { horizontal: 'center' }, font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '42307d' } } },
    },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {
      v: dictionary().COSTS_COLUMN_CSV,
      t: 's',
      s: { alignment: { horizontal: 'center' }, font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '263238' } } },
    },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {
      v: dictionary().FRAUD,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: 'e76c6c' } } },
    },
    {},
  ]
  const row = [
    {
      v: dictionary().FUEL_CONSUMPTION,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: '000000' } }, fill: { fgColor: { rgb: 'fce4d7' } } },
    },
    {
      v: dictionary().ODOMETER_READING,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: '000000' } }, fill: { fgColor: { rgb: 'fce4d7' } } },
    },
    {
      v: dictionary().ODOMETER_READING_STATUS,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: '000000' } }, fill: { fgColor: { rgb: 'fce4d7' } } },
    },
    {
      v: dictionary().PUMP_READING,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: '000000' } }, fill: { fgColor: { rgb: 'fce4d7' } } },
    },
    {
      v: dictionary().DISTANCE_TRAVELED,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: '000000' } }, fill: { fgColor: { rgb: 'fce4d7' } } },
    },
    {
      v: dictionary().DIFFERENCE_RATIO,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: '000000' } }, fill: { fgColor: { rgb: 'c6e0b3' } } },
    },
    {
      v: dictionary().AMOUNT,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: '000000' } }, fill: { fgColor: { rgb: 'c6e0b3' } } },
    },
    {
      v: dictionary().LITRES,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: '000000' } }, fill: { fgColor: { rgb: 'c6e0b3' } } },
    },
    {
      v: dictionary().STATUS,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().VALIDITY,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().FUEL_TYPES,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().CORPORATE_NAME,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().STATIONS_NAME,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().STAFF_NAME,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().DRIVER_NAME,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().NUMBER_PLATE,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().CODE,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().VEHICLE_TYPE,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().DATE,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().TIME,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().TRANSACTION_NUMBER,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '808080' } } },
    },
    {
      v: dictionary().REASON,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '7f56d9' } } },
    },
    {
      v: dictionary().ACTUAL_DISTANCE,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '7f56d9' } } },
    },
    {
      v: dictionary().ACTUAL_FUEL_CONSUMPTION,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '7f56d9' } } },
    },
    {
      v: dictionary().CORPORATE_ID,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '7f56d9' } } },
    },
    {
      v: dictionary().STATION_ID,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '7f56d9' } } },
    },
    {
      v: dictionary().VEHICLE_ID,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '7f56d9' } } },
    },
    {
      v: dictionary().VARIANCE_STATUS,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '7f56d9' } } },
    },
    {
      v: dictionary().REFUNDED_TRANSACTION,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '7f56d9' } } },
    },
    {
      v: dictionary().TIPS_AMOUNT,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
    {
      v: dictionary().TIPS_REFUND,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
    {
      v: dictionary().VAT_AMOUNT,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
    {
      v: dictionary().VAT_REFUND,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
    {
      v: dictionary().TOTAL_SERVICE_FEE,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
    {
      v: dictionary().STATION_TIPS,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
    {
      v: dictionary().STATION_TIPS_REFUND,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
    {
      v: dictionary().STATION_TIPS_VAT,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
    {
      v: dictionary().STATION_TIPS_VAT_REFUND,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
    {
      v: dictionary().STATION_TOTAL_AMOUNT,
      t: 's',
      s: { font: { bold: true, sz: '12', italic: true, color: { rgb: 'FFFFFF' } }, fill: { fgColor: { rgb: '546E7A' } } },
    },
  ]

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
    { s: { r: 0, c: 5 }, e: { r: 0, c: 7 } },
    { s: { r: 0, c: 8 }, e: { r: 0, c: 20 } },
    { s: { r: 0, c: 21 }, e: { r: 0, c: 28 } },
    { s: { r: 0, c: 29 }, e: { r: 0, c: 38 } },
    // { s: { r: 0, c: 39 }, e: { r: 0, c: 40 } },
  ]
  const ws = pathname === '/fuel-transactions' ? XLSX.utils.aoa_to_sheet([fristRow, row, ...values]) : XLSX.utils.aoa_to_sheet([...values])
  pathname === '/fuel-transactions' && (ws['!merges'] = merge)
  const wscols = []
  let cell = 0
  while (cell < row.length) {
    wscols.push({ wch: 20 })
    cell++
  }

  ws['!cols'] = wscols
  if (pathname.length > 14) {
    const url = pathname.slice(1).split('/')
    pathname = url[0]
  } else {
    pathname = pathname.replace(/\//g, '-').slice(1)
  }
  XLSX.utils.book_append_sheet(wb, ws, `${pathname.replace(/\//g, '-').slice(1)}`)
  XLSX.writeFile(wb, `${getFileName(pathname, 'XLSX')}`)
}

const CsvExport = (values: any, keysArray: any, pathname: string) => {
  const unparsedData = {
    download: true,
    encoding: 'utf-8-sig',
    columns: [...keysArray],
  }
  const unparsed = Papa.unparse<any>(values, unparsedData as any)
  const csvData = new Blob(['\ufeff' + unparsed])
  let csvURL = null
  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvData, 'download.csv')
  } else {
    csvURL = window.URL.createObjectURL(csvData)
  }
  const tempLink: any = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', `${getFileName(pathname, 'CSV')}`)
  tempLink.click()
}

const fillCsvColumn = (
  data: any,
  values: any,
  keysArray: any,
  namesArray: any,
  setIsFetching: any,
  setIsXlsxFetching: any,
  type: string,
  pathname: string,
  corporateGroups: any,
  account_id: any,
) => {
  if (pathname === '/fuel-transactions') {
    type === 'CSV' && values.push(namesArray)
  } else {
    values.push(namesArray)
  }
  data = data?.data ? data?.data : data
  data?.map((item: any) => {
    let n = 0

    values.push(
      keysArray.map((header: any, k: any) => {
        const value = header.format ? header.format(path(header.split('.'), item), item) : path(header.split('.'), item)

        if (header === 'ledger_accounts') {
          let x: string | number
          x = ''
          value?.map((acc: any) => {
            namesArray.map((s: any, i: any) => {
              if (s === 'fuel_balance' || s === 'الرصيد المتاح') {
                if (acc?.account_type === 'FUEL' && i === k) {
                  x = acc['balance'] === 0 ? 0 : acc['balance']
                }
              } else if (s === 'store_balance' && i === k) {
                if (acc?.account_type === 'STORE') {
                  x = acc['balance']
                }
              } else if (s === 'toll_balance' && i === k) {
                if (acc?.account_type === 'TOLL') {
                  x = acc['balance']
                }
              } else if (s === 'cash_balance' && i === k) {
                if (acc?.account_type === 'CASH') {
                  x = acc['balance'] === 0 ? 0 : acc['balance']
                }
              } else if (s === 'consumed_balance' && i === k) {
                if (acc?.account_type === 'CONSUMPTION') {
                  x = acc['balance'] === 0 ? 0 : acc['balance']
                } else x = 0
              } else {
              }
            })
          })
          value?.length === 0 && (x = 0)
          return +x
        }
        if (header === 'Renewal_date') {
          const date = new Date(value)
          date.setFullYear(date.getFullYear() + 1)
          return format(new Date(date as any), 'yyyy/MM/dd')
        }
        if (header === 'total') {
          return item.amount_due_per_vehicle * +item.count
        }
        if (header === 'pump_reading') {
          return getStatusValue(value, item?.pump_reading_error_status, item)
        }
        if (header === 'credit_balance') {
          if (account_id == item.credit_account) {
            return item?.entry_type === 'WITHDRAWAL' ? '-' : item.amount
          } else {
            return item?.entry_type === 'WITHDRAWAL' ? item.amount : '-'
          }
        }
        if (header === 'debit_balance') {
          if (account_id == item.debit_account) {
            return item?.entry_type === 'WITHDRAWAL' ? '-' : item.amount
          } else {
            return item?.entry_type === 'WITHDRAWAL' ? item.amount : '-'
          }
        }
        if (header === 'balance') {
          if (account_id == item.debit_account) {
            return item.debit_balance
          } else if (account_id == item.credit_account) {
            return item.credit_balance
          }
        }
        if (header === 'odometer_reading_status') {
          return getStatusValue(value, item?.odometer_reading_status, item)
        }
        if (header === 'correction') {
          return item.correction && Number(item?.correction).toFixed(2)
        }
        if (header === 'tips_amount') {
          return formatDecimal(item?.tips_amount)
        }
        if (header === 'tips_refund') {
          return formatDecimal(item?.tips_refund)
        }
        if (header === 'vat') {
          return formatDecimal(item?.vat)
        }
        if (header === 'vat_tips_refund') {
          return formatDecimal(item?.vat_tips_refund)
        }
        if (header === 'total_service_fee') {
          return formatDecimal(item?.total_service_fee)
        }
        if (header === 'station_tips') {
          return formatDecimal(item?.station_tips)
        }
        if (header === 'station_tips_refund') {
          return formatDecimal(item?.station_tips_refund)
        }
        if (header === 'station_tips_vat') {
          return formatDecimal(item?.station_tips_vat)
        }
        if (header === 'station_tips_vat_refund') {
          return formatDecimal(item?.station_tips_vat_refund)
        }
        if (header === 'station_total_amount') {
          return formatDecimal(item?.station_total_amount)
        }
        if (header === 'payment_method') {
          switch (value) {
            case 'CASH':
              return dictionary().CASH
            case 'TRANSFER':
              return dictionary().TRANSFER
            case 'VEHICLES_SUBSCRIPTION':
              return dictionary().VEHICLES_SUBSCRIPTION
            case 'CHEQUE':
              return dictionary().CHEQUE
            case 'BANK_DEPOSIT':
              return dictionary().BANK_DEPOSIT
            case 'BANK_TRANSFER':
              return dictionary().BANK_TRANSFER
            default:
              return value
          }
        }
        if (header === 'entity') {
          return item?.corporate_name || item?.corporate_group_name
        }
        if (header === 'status') {
          const key = String(value).toLocaleLowerCase()
          if (key == 'confirmed') {
            return dictionary().CONFIRMED
          } else if (key == 'pending') {
            return dictionary().PENDING
          } else if (key == 'void' || key == 'canceled') {
            return dictionary().CANCELED
          } else if (key == 'unpaid') {
            return dictionary().UNPAID
          } else if (key == 'paid') {
            return dictionary().PAID
          } else if (key == 'patially_paid') {
            return dictionary().PARTIALLY_PAID
          } else if (key == 'external') {
            return dictionary().EXTERNAL
          } else if (key == 'rejected') {
            return dictionary().REJECTED
          } else if (key == 'initial_confirmation') {
            return dictionary().INITIAL_CONFIRMATION
          } else if (key == 'initial_rejection') {
            return dictionary().INITIAL_REJECTION
          }
        }
        if (header === 'is_invalid') {
          return CheckValidity(item)
        }
        if (header === 'distance_traveled_errors') {
          return getDistanceTraveledErrors(value, item)
        }
        if (header === 'tax_ids_corporates') {
          const { customerNames } = item
          return customerNames.join(', ')
        }
        if (header === 'corporate_id') {
          return item.corporate_id
        }
        if (header === 'account_type') {
          return 'FUEL'
        }
        if (header === 'driver_name') {
          return item.name
        }
        if (header === 'corporateGroupId') {
          return getCorporateGroupName(item, corporateGroups)
        }
        if (header === 'balance_fuel') {
          return getAccount(item, 'FUEL')
        }
        if (header === 'balance_store') {
          return getAccount(item, 'STORE')
        }
        if (header === 'balance_toll') {
          return getAccount(item, 'TOLL')
        }
        if (header === 'balance_cash') {
          return getAccount(item, 'CASH')
        }
        if (header === 'correction_status') {
          switch (value) {
            case 'NORMAL':
              return dictionary().NORMAL
            case 'VARIANCE':
              return dictionary().VARIANCE
            case 'VARIANCE_REFUND':
              return dictionary().VARIANCE_REFUND
            case 'DUPLICATED':
              return dictionary().DUPLICATED
            case 'DUPLICATE_REFUND':
              return dictionary().DUPLICATED_REFUND
          }
        }
        if (header === 'plate_number') {
          return item.number_plate || item.plate_number
        } else if (header === 'vehicle_plate_number') {
          return item.vehicles.length === 1 ? item.vehicles[0]?.number_plate : item.vehicles.length === 0 ? dictionary().NO_VEHICLES : dictionary().MULTIPLE
        } else if (header === 'vehicle_code') {
          return item.vehicles.length === 1 ? item.vehicles[0]?.code : item.vehicles.length === 0 ? dictionary().NO_VEHICLES : dictionary().MULTIPLE
        } else if (header === 'Vehicle_driver_name') {
          return item.drivers.length === 1 ? item.drivers[0]?.name : item.drivers.length === 0 ? dictionary().NO_DRIVERS : dictionary().MULTIPLE
        }
        if (header === 'date') {
          const date = format(new Date(value as any), 'ccc MMM dd yyyy')
          return date
        }
        if (header === 'created_at') {
          if (n == 0) {
            n++
            const date = format(new Date(value as any), 'ccc MMM dd yyyy')

            return date
          } else {
            const time = format(new Date(value as any), 'h:mm:ss aa')

            n = 0
            return time
          }
        } else if (pathname !== '/fuel-transactions' && header === 'driver.id') {
          return `D-${value}`
        } else if (pathname !== '/fuel-transactions' && header === 'station_provider.id') {
          return `V-${value}`
        } else if (pathname !== '/fuel-transactions' && header === 'corporate.id') {
          return `C-${value}`
        } else if (pathname !== '/fuel-transactions' && header === 'station.id') {
          return `S-${value}`
        } else if (header === 'is_fraud') {
          return value === null ? dictionary().IS_READING : value ? '✔' : '✘'
        } else if (header === 'date_and_time') {
          const dateTime = moment.utc(value as any).format('hh:mmA - DD/MM/yyyy')
          return dateTime
        } else {
          return value
        }
      }),
    )
  })
  if (type === 'CSV') {
    CsvExport(values, keysArray, pathname)
    setIsFetching(false)
  } else if (type === 'XLSX') {
    XlsxExport(values, pathname)
    setIsXlsxFetching(false)
  }
}
const handleData = (data: any) => {
  if (!Array.isArray(data.data)) {
    const keys = Object.keys(data?.data)
    const dataRow: any[] = []
    keys?.map((date) => {
      data?.data[date]?.map((item: any) => {
        item.Renewal_date = date
        dataRow.push(item)
      })
    })
    return dataRow
  }
  return data
}

const CsvDownloader: React.FC<Props> = ({
  query,
  headers = [],
  querystringToVariables = createVariablesFromQueryString,
  restRoute,
  isUpload,
  apiHeader,
  accounting,
  vehicleSubscriptionData,
  renewalDate,
}) => {
  const { role } = useStore()

  const { id } = useParams<{ id: string }>()
  const { account_id } = useParams<{ account_id: string }>()
  const { pathname } = useLocation()
  let corporateGroups: any = []
  pathname.includes('/corporates') ? (corporateGroups = useQuery(['corporate-group'], () => fetch('GET', `/corporate-group`), {})) : ''

  const variables = querystringToVariables(query)

  const fuelTransactionsHeaders = [
    { id: 'fuel_conumption', key: 'fuel_consumption' },
    { id: 'odometer_reading', key: 'odometer_reading' },
    { id: 'odometer_reading_status', key: 'odometer_reading_status' },
    { id: 'pump_reading', key: 'pump_reading' },
    { id: 'distance_traveled', key: 'distance_traveled' },
    { id: 'correction', key: 'correction' },
    { id: 'amount', key: 'amount' },
    { id: 'liters', key: 'liters' },
    { id: 'status', key: 'status' },
    { id: 'is_invalid', key: 'is_invalid' },
    { id: 'fuelType', key: 'fuel_type.name.en' },
    { id: 'corporate', key: 'corporate.name' },
    { id: 'gasStation', key: 'station.name.en' },
    { id: 'fuelAttendant', key: 'station_staff.name.ar' },
    { id: 'driver', key: 'driver.name' },
    { id: 'plateNumber', key: 'vehicle.number_plate' },
    { id: 'vehicle_code', key: 'vehicle.code' },
    { id: 'vehicle_type', key: 'vehicle.vehicle_type.name.ar' },
    { id: 'date', key: 'created_at' },
    { id: 'time', key: 'created_at' },
    { id: 'id', key: 'id' },
    { id: 'reason', key: 'distance_traveled_errors' },
    { id: 'distance_traveled', key: 'original_distance_traveled' },
    { id: 'fuel_conumption', key: 'original_fuel_consumption' },
    { id: 'corporate ID', key: 'corporate.id' },
    { id: 'gasStation ID', key: 'station.id' },
    { id: 'Vehicle ID', key: 'vehicle.id' },
    { id: 'Variance Status', key: 'correction_status' },
    { id: 'Refunded #Tx', key: 'correction_reference_id' },
    { id: 'tips_amount', key: 'tips_amount' },
    { id: 'tips_refund', key: 'tips_refund' },
    { id: 'vat', key: 'vat' },
    { id: 'vat_tips_refund', key: 'vat_tips_refund' },
    { id: 'total_service_fee', key: 'total_service_fee' },
    { id: 'station_tips', key: 'station_tips' },
    { id: 'station_tips_refund', key: 'station_tips_refund' },
    { id: 'station_tips_vat', key: 'station_tips_vat' },
    { id: 'station_tips_vat_refund', key: 'station_tips_vat_refund' },
    { id: 'station_total_amount', key: 'station_total_amount' },
    { id: 'is_fraud', key: 'is_fraud' },
  ]
  const fuelTransactionsHeadersForCSV = [
    { id: 'id', key: 'id' },
    { id: 'date', key: 'created_at' },
    { id: 'time', key: 'created_at' },
    { id: 'driver', key: 'driver.name' },
    { id: 'corporate', key: 'corporate.name' },
    { id: 'fuelAttendant', key: 'station_staff.name.ar' },
    { id: 'gasStation', key: 'station.name.en' },
    { id: 'liters', key: 'liters' },
    { id: 'fuelType', key: 'fuel_type.name.en' },
    { id: 'status', key: 'status' },
    { id: 'is_invalid', key: 'is_invalid' },
    { id: 'amount', key: 'amount' },
    { id: 'correction', key: 'correction' },
    { id: 'fuel_conumption', key: 'fuel_consumption' },
    { id: 'odometer_reading', key: 'odometer_reading' },
    { id: 'odometer_reading_status', key: 'odometer_reading_status' },
    { id: 'plateNumber', key: 'vehicle.number_plate' },
    { id: 'vehicle_code', key: 'vehicle.code' },
    { id: 'pump_reading', key: 'pump_reading' },
    { id: 'distance_traveled', key: 'distance_traveled' },
    { id: 'vehicle_type', key: 'vehicle.vehicle_type.name.ar' },
    { id: 'actual_distance_traveled', key: 'original_distance_traveled' },
    { id: 'acutal_fuel_conumption', key: 'original_fuel_consumption' },
    { id: 'reason', key: 'distance_traveled_errors' },
    { id: 'gasStation ID', key: 'station.id' },
    { id: 'corporate ID', key: 'corporate.id' },
    { id: 'Vehicle ID', key: 'vehicle.id' },
    { id: 'Variance Status', key: 'correction_status' },
    { id: 'Refunded #Tx', key: 'correction_reference_id' },
    { id: 'tips_amount', key: 'tips_amount' },
    { id: 'tips_refund', key: 'tips_refund' },
    { id: 'vat', key: 'vat' },
    { id: 'vat_tips_refund', key: 'vat_tips_refund' },
    { id: 'total_service_fee', key: 'total_service_fee' },
    { id: 'station_tips', key: 'station_tips' },
    { id: 'station_tips_refund', key: 'station_tips_refund' },
    { id: 'station_tips_vat', key: 'station_tips_vat' },
    { id: 'station_tips_vat_refund', key: 'station_tips_vat_refund' },
    { id: 'station_total_amount', key: 'station_total_amount' },
    { id: 'is_fraud', key: 'is_fraud' },
  ]

  const accountingFuelTransactionsHeaders = [
    { id: 'id', key: 'id' },
    { id: 'date', key: 'created_at' },
    { id: 'time', key: 'created_at' },
    { id: 'driver ID', key: 'driver.id' },
    { id: 'driver', key: 'driver.name' },
    { id: 'corporate ID', key: 'corporate.id' },
    { id: 'corporate', key: 'corporate.name' },
    { id: 'fuelAttendant', key: 'station_staff.name.en' },
    { id: 'station ID', key: 'station.id' },
    { id: 'station', key: 'station.name.ar' },
    { id: 'vendor SP ID', key: 'station_provider.id' },
    { id: 'vendor SP', key: 'station_provider.name.en' },
    { id: 'liters', key: 'liters' },
    { id: 'fuelType', key: 'fuel_type.name.en' },
    { id: 'status', key: 'status' },
    { id: 'amount', key: 'amount' },
    { id: 'correction', key: 'correction' },
    { id: 'fuel_conumption', key: 'fuel_consumption' },
    { id: 'odometer_reading', key: 'odometer_reading' },
    { id: 'plateNumber', key: 'vehicle.number_plate' },
    { id: 'vehicle_code', key: 'vehicle.code' },
    { id: 'pump_reading', key: 'pump_reading' },
    { id: 'comment', key: 'comment' },
  ]
  const { from, to: toFilter, corporateIds } = useFilterStore()

  const [isFetching, setIsFetching] = useState(false)
  const [isXlsxFetching, setIsXlsxFetching] = useState(false)
  const csvDownloader = (type: string) => {
    const isPaymentDetails = pathname === '/payments-details'
    const keysArray: any = []
    let namesArray: any = []
    const values: any = []
    type === 'XLSX' ? setIsXlsxFetching(true) : setIsFetching(true)
    const endpoint = isPaymentDetails ? '/deposit-request/filter?page=1&sort_direction=DESC&sort_column=id&language=ar' : restRoute

    if (isUpload) {
      if (pathname === '/e-invoices') {
        if (from && toFilter && corporateIds.length > 0) {
          fetch('POST', restRoute, {
            data: {
              startDate: from,
              endDate: toFilter,
              corporateIds: corporateIds,
            },
          }).then((data) => {
            const dataObj = data?.data
            let sortedData = dataObj.sort((a: any, b: any) => {
              if (a.contact_name < b.contact_name) {
                return -1
              }
              if (a.contact_name > b.contact_name) {
                return 1
              }
              return 0
            })

            headers.map((header: any) => {
              keysArray.push(header.key)
              namesArray.push(header?.name ?? header?.id)
            })
            keysArray.pop()
            namesArray.pop()
            const newData = handleData({ data: sortedData })
            fillCsvColumn(newData, values, keysArray, namesArray, setIsFetching, setIsXlsxFetching, type, pathname, corporateGroups, account_id)
          })
        } else {
          return
        }
      } else {
        fetch('GET', endpoint, { query: { ...variables, page_size: isPaymentDetails ? 1000000 : 0 } }).then((data) => {
          if (accounting) {
            accountingFuelTransactionsHeaders.map((header: any) => {
              if (header.key !== 'ledger_accounts') {
                keysArray.push(header.key)
                namesArray.push(header.id)
              }
            })
          } else if (restRoute === '/fuel-transactions') {
            type === 'XLSX'
              ? fuelTransactionsHeaders.map((header: any) => {
                  if (header.key !== 'ledger_accounts') {
                    keysArray.push(header.key)
                    namesArray.push(header.id)
                  }
                })
              : fuelTransactionsHeadersForCSV.map((header: any) => {
                  if (header.key !== 'ledger_accounts') {
                    keysArray.push(header.key)
                    namesArray.push(header.id)
                  }
                })
          } else {
            headers.map((header: any) => {
              keysArray.push(header.key)
              namesArray.push(header?.name ?? header?.id)
            })
          }
          const newData = handleData(data)
          fillCsvColumn(newData, values, keysArray, namesArray, setIsFetching, setIsXlsxFetching, type, pathname, corporateGroups, account_id)
        })
      }
    } else if (!vehicleSubscriptionData) {
      fetch('GET', apiHeader, { query: { corporateId: id } }).then((data) => {
        namesArray = data?.headers ? [...data?.headers] : data
        data = data?.drivers ? data?.drivers : data?.vehicles ? data?.vehicles : data
        fillCsvColumn(data, values, namesArray, namesArray, setIsFetching, setIsXlsxFetching, type, pathname, corporateGroups, account_id)
      })
    } else {
      headers.map((header: any) => {
        keysArray.push(header.key)
        namesArray.push(header.id)
      })
      vehicleSubscriptionData.map((subscription: any) => {
        subscription.Renewal_date = renewalDate
      })
      fillCsvColumn(vehicleSubscriptionData, values, keysArray, namesArray, setIsFetching, setIsXlsxFetching, type, pathname, corporateGroups, account_id)
    }
  }
  return (
    <>
      {canDownload(role) && (
        <>
          <Button
            backgroundColor={'#EEF2F6'}
            borderRadius={'5px'}
            padding={'10px'}
            fontWeight={'500'}
            fontSize={'12px'}
            data-test='download-csv'
            disabled={isFetching}
            onClick={async () => {
              csvDownloader('CSV')
            }}
          >
            {isFetching ? <Spinner /> : dictionary().DOWNLOAD_CSV}
          </Button>
          <Button
            backgroundColor={'#EEF2F6'}
            borderRadius={'5px'}
            padding={'10px'}
            fontWeight={'500'}
            fontSize={'12px'}
            data-test='download-xlsx'
            disabled={isXlsxFetching}
            onClick={async () => {
              csvDownloader('XLSX')
            }}
          >
            {isXlsxFetching ? <Spinner /> : dictionary().DOWNLOAD_XLSX}
          </Button>
        </>
      )}
    </>
  )
}

export default CsvDownloader
