import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import FuelTransactionTable from '../../components/FuelTransactionTable/FuelTransactionTable'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { getFilters } from '../../helpers/getFilters'
import useAlert from '../../helpers/useAlert'
import { useStore } from '../../store'
import { useFilterStore } from '../../stores/filterStore'

const FuelTransactions: React.FC = () => {
  const state = useFilterStore()
  const {
    from,
    language,
    page,
    page_size,
    positiveValues,
    sort_column,
    sort_direction,
    status,
    to,
    stationId,
    corporateId,
    id,
    transactionIds,
    corporateIds,
    stationIds,
    vehicleIds,
    driverIds,
  } = state
  const { userType } = useStore((state) => state)
  const userData = window.localStorage.getItem('activeRole')
  const { role } = useStore()

  const { onSuccess, onError } = useAlert()

  const { isLoading, mutate, data } = useMutation((vars: any) => fetch('POST', `/fuel-transactions/filter`, { data: vars }), {
    onSuccess: (): void => {},
    onError,
  })

  useEffect(() => {
    const filters = getFilters(state)
    mutate(filters)
  }, [
    from,
    status,
    to,
    positiveValues,
    language,
    sort_column,
    sort_direction,
    page,
    page_size,
    stationId,
    corporateId,
    id,
    //--------------- Converting arrays to strings to make a stable comparison instead of a shallow comparison ------------------
    JSON.stringify(transactionIds),
    JSON.stringify(corporateIds),
    JSON.stringify(stationIds),
    JSON.stringify(vehicleIds),
    JSON.stringify(driverIds),
    //---------------------------------------------------------------------------------------------------------------------------
  ])

  // if (!isRendered) {
  //   if (userType === USER_TYPE.ADMIN || userType === USER_TYPE.STATION_PROVIDER_STAFF) {
  //     headers.splice(13, 0, {
  //       id: 'station_provider_fee',
  //       key: 'station_provider_fee',
  //       name: dictionary().STATION_PROVIDER_FEE,
  //       type: 'cash',
  //     })
  //   }
  //   if (userType === USER_TYPE.ADMIN || userType === USER_TYPE.CORPORATE_STAFF || userType === USER_TYPE.CORPORATE_GROUP_STAFF) {
  //     headers.push({
  //       id: 'OdometerImageModal',
  //       key: 'images.odometer',
  //       name: dictionary().ODOMETER_IMAGES,
  //       type: 'modal',
  //     })
  //   }
  //   if ((userType === USER_TYPE.ADMIN || userType === USER_TYPE.CORPORATE_STAFF || userType === USER_TYPE.CORPORATE_GROUP_STAFF) && role !== 'FIN_OPS') {
  //     headers.push({
  //       id: 'updateOdometerReadingModal',
  //       key: 'updateOdometerReadingModal',
  //       name: dictionary().UPDATE_ODOMETER_READING,
  //       type: 'modal',
  //     })
  //   }
  //   if (userType === USER_TYPE.ADMIN && role !== 'FIN_OPS') {
  //     headers.push({
  //       id: 'confirmTransactionModal',
  //       key: 'confirmTransaction',
  //       name: dictionary().ACTIONS,
  //       type: 'modal',
  //     })
  //   }

  //   isRendered = true
  // }

  return (
    <ViewAll
      headers={[]}
      title={dictionary().FUEL_TRANSACTIONS}
      to='/fuel-transactions/new'
      tags={[
        { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
        { name: dictionary().CONFIRMED, filter: [{ name: 'status', value: 'CONFIRMED' }] },
      ]}
      hasSearch={true}
      hasSearchDate={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['fuel-transaction']}
      countArrayPath={['count']}
      restRoute={'/fuel-transactions'}
      cashingKey={CASHING_KEY.GET_FUEL_TRANSACTIONS}
      enableIdRedirect={false}
      table={() => FuelTransactionTable({ data, loading: isLoading })}
      fuelData={data}
    />
  )
}

export default FuelTransactions
