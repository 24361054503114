import { Box, Divider, Flex, GridItem, ModalBody, ModalHeader, Skeleton, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { FC } from 'react'
import dictionary from '../../../dictionary'
import { EditedData, FileUploadData, Record } from './BulkUploadType'
import BulkUploadValidationInfoText from './BulkUploadValidationInfoText'

import ValidationModalCell from './ValidationModalCell'
import ValidationModalFooter from './ValidationModalFooter'

type Props = {
  headers: string[]
  fileUploadData: FileUploadData | undefined
  close: () => void
  revalidateFileUploadMutatation: { revalidateFileUploadMutate: () => void; revalidateFileUploadIsLoading: boolean }
  downloadCorrectedFileMutatation: {
    downloadCorrectedFileMutate: () => void
    downloadCorrectedFileIsLoading: boolean
  }
  finalUploadMutatation: { finalUploadMutate: () => void; finalUploadIsLoading: boolean }
  editedData: EditedData
  setEditedData: (edited: EditedData) => void
  numberOfSkeletonRows: number
}

const ValidationModal: FC<Props> = ({
  headers,
  fileUploadData,
  close,
  editedData,
  setEditedData,
  revalidateFileUploadMutatation,
  downloadCorrectedFileMutatation,
  finalUploadMutatation,
  numberOfSkeletonRows,
}) => {
  //------------------------------------- Handle input change ---------------------------------------
  const handleInputChange = (value: string, record: Record, header: string) => {
    const existingRecordIndex = editedData.findIndex((item) => item.id === record.id)

    //------------- Flatten data and Extract only the value into an object ------------
    const flattenedData = Object.keys(record.data).reduce((cell, key) => {
      cell[key] = record.data[key]?.value ?? ''
      return cell
    }, {} as { [key: string]: string })
    //---------------------------------------------------------------------------------

    if (existingRecordIndex > -1) {
      //------------------ Update the existing record ---------------------
      const updatedData = [...editedData]
      updatedData[existingRecordIndex] = {
        ...flattenedData,
        ...updatedData[existingRecordIndex],
        [header]: value,
        id: record.id,
      }
      setEditedData(updatedData)
      //-------------------------------------------------------------------
    } else {
      //----------------------- Add a new record --------------------------
      setEditedData([
        ...editedData,
        {
          ...flattenedData,
          [header]: value,
          id: record.id,
        },
      ])
      //-------------------------------------------------------------------
    }
  }
  //-------------------------------------------------------------------------------------------------

  return (
    <>
      <ModalHeader style={{ direction: 'rtl' }} fontWeight='600' fontSize='16px' color='#000000'>
        {dictionary().UPLAOD_FILES}
        <Divider bg={'#D0D5DD'} height={'1px'} mt={'10px'} />
      </ModalHeader>

      <ModalBody overflowX={'scroll'} overflowY={'scroll'} pb={6}>
        <GridItem width={'fit-content'} style={{ direction: 'rtl' }} mb={1}>
          <Flex style={{ direction: 'rtl' }} justifyContent='space-between' alignItems={'center'} paddingTop={'25px'}>
            <BulkUploadValidationInfoText
              label={dictionary().NUMBER_OF_RECORDS}
              value={fileUploadData?.records_count.toString()!}
              isLoading={revalidateFileUploadMutatation?.revalidateFileUploadIsLoading}
            />
            <BulkUploadValidationInfoText
              label={dictionary().NUMBER_OF_SUCCESSFUL_RECORDS_UPLOADED}
              value={fileUploadData ? (fileUploadData?.invalid_records_count - fileUploadData?.records_count).toString()! : ''}
              valueColor={'#42B48B'}
              isLoading={revalidateFileUploadMutatation?.revalidateFileUploadIsLoading}
            />
            <BulkUploadValidationInfoText
              label={dictionary().NUMBER_OF_RECORDS_WEITH_ERRORS_VALIDATION_MODAL}
              value={fileUploadData?.invalid_records_count.toString()!}
              valueColor={'#B42318'}
              isLoading={revalidateFileUploadMutatation?.revalidateFileUploadIsLoading}
            />
            <BulkUploadValidationInfoText
              label={dictionary().TOTAL_NUMBER_OF_ERROS}
              value={fileUploadData?.invalid_fields_count.toString()!}
              valueColor={'#B42318'}
              containerProps={{ ml: '12%' }}
              isLoading={revalidateFileUploadMutatation?.revalidateFileUploadIsLoading}
            />
          </Flex>

          <Flex style={{ direction: 'rtl' }} justifyContent='center' alignItems={'center'} flexDirection={'column'} paddingTop={'25px'}>
            <Box>
              <Table variant='simple'>
                <Thead>
                  <Tr borderWidth={'1px'} zIndex={2} borderColor={'#EBEBEB'} backgroundColor={'#DBD9F2'} position='sticky' top={-2}>
                    {headers?.map((header: string) => (
                      //--------------------- Set the width of the table header based on the number of headers ---------------------
                      //------------------------- the less the number of headers the wider each header is --------------------------
                      <Th minWidth={'110px'} color={'#000000'} key={header} textAlign={'center'} width={`${30000 / headers?.length}px`} whiteSpace='nowrap'>
                        {header}
                      </Th>
                      //------------------------------------------------------------------------------------------------------------
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {revalidateFileUploadMutatation?.revalidateFileUploadIsLoading
                    ? Array(numberOfSkeletonRows)
                        .fill(null)
                        .map((_, rowIndex) => (
                          <Tr
                            zIndex={1}
                            key={rowIndex}
                            borderWidth={'1px'}
                            borderColor={'#EBEBEB'}
                            backgroundColor={rowIndex % 2 === 0 ? '#F2F5F9' : '##EBEBEB'}
                          >
                            {headers?.map((_, cellIndex) => (
                              <Th key={cellIndex} textAlign='center' p={'4'}>
                                <Skeleton height={'30px'} />
                              </Th>
                            ))}
                          </Tr>
                        ))
                    : fileUploadData?.data?.map((record: Record, index) => (
                        <Tr key={record?.id} borderWidth={'1px'} borderColor={'#EBEBEB'} backgroundColor={index % 2 === 0 ? '#F2F5F9' : '##EBEBEB'}>
                          {headers?.map((header: string) => (
                            <ValidationModalCell record={record} header={header} editedData={editedData} handleInputChange={handleInputChange} />
                          ))}
                        </Tr>
                      ))}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </GridItem>
      </ModalBody>

      <ValidationModalFooter
        close={close}
        revalidateFileUploadMutatation={revalidateFileUploadMutatation}
        downloadCorrectedFileMutatation={downloadCorrectedFileMutatation}
        finalUploadMutatation={finalUploadMutatation}
        fileUploadData={fileUploadData!}
      />
    </>
  )
}

export default ValidationModal
