import React from 'react'
import { Table, Box } from '@chakra-ui/react'
import { TableProps } from '../../../interfaces/tableProps'
import { TableHead } from './TableHead'
import { TableBody } from './TableBody'

const TableComponent: React.FC<TableProps> = ({
  height,
  headers,
  data,
  raised,
  enableIdRedirect,
  handleIsDriverRemoved,
  renewalDate,
  footer,
  handleIsBalancesChange,
  register,
  errors,
  width,
}) => {
  const raisedProps = raised ? { borderRadius: 'lg', boxShadow: '1px', borderWidth: '1px', bg: 'white' } : {}
  return (
    <Box maxH={height ?? '70vh'} w={width} overflowY='auto' {...raisedProps}>
      <Table variant='simple' data-test='table'>
        <TableHead headers={headers} />
        {enableIdRedirect === false ? (
          <TableBody
            headers={headers}
            data={data}
            enableIdRedirect={enableIdRedirect}
            handleIsDriverRemoved={handleIsDriverRemoved}
            renewalDate={renewalDate}
            footer={footer}
            handleIsBalancesChange={handleIsBalancesChange}
            register={register}
            errors={errors}
          />
        ) : (
          <TableBody headers={headers} data={data} enableIdRedirect={true} handleIsDriverRemoved={handleIsDriverRemoved} renewalDate={renewalDate} />
        )}
      </Table>
    </Box>
  )
}

export default TableComponent
