import { Box, Flex, GridItem, Stack, Text } from '@chakra-ui/react'
import { pathOr } from 'ramda'
import React from 'react'
import Card from '../../components/core/Card'
import dictionary from '../../dictionary'
import Table from '../core/Table'

interface Props {
  data: any
  isLoading?: boolean
  handleIsBalanseChanged?: () => void
}

const stationStaffHeaders = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'driverRedirect',
  },

  {
    id: 'name',
    key: 'name',
    name: dictionary().NAME,
    type: 'text',
  },
  {
    id: 'unassignDriver',
    key: 'id',
    name: '',
    type: 'modal',
  },
]

const VehicleAssignedDrivers: React.FC<Props> = ({ data, isLoading, handleIsBalanseChanged }) => {
  return (
    <>
      <Card h='100%' p={4}>
        <Text m={3} fontSize='20px'>
          {dictionary().ASSIGNED_DRIVERS}
        </Text>
        <GridItem m='2' colSpan={6}>
          <Box w='100%'>
            <Stack>
              <GridItem>
                {data?.vehicle?.drivers?.length > 0 ? (
                  <Table
                    raised
                    enableIdRedirect={true}
                    height={300}
                    headers={stationStaffHeaders}
                    data={pathOr([], ['drivers'], data?.vehicle)}
                    handleIsDriverRemoved={handleIsBalanseChanged}
                  />
                ) : isLoading ? (
                  <Flex w='100%' h='100%' align='center' justify='center'>
                    <Text fontSize='2xl'>
                      {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().ASSIGNED_DRIVERS}
                    </Text>
                  </Flex>
                ) : (
                  <Flex w='100%' h='100%' align='center' justify='center'>
                    <Text fontSize='2xl'>
                      {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {dictionary().ASSIGNED_DRIVERS}
                    </Text>
                  </Flex>
                )}
              </GridItem>
            </Stack>
          </Box>
        </GridItem>
      </Card>
    </>
  )
}

export default VehicleAssignedDrivers
