import dictionary from '../../dictionary'
import { ROLES } from '../ROLES'

export const getCorporateActions = (role: string): any => {
  switch (role) {
    case ROLES.ACCOUNT_MANAGER:
      break
    default:
      return [
        {
          type: 'path',
          action: 'edit',
          title: dictionary().EDIT,
        },
        {
          type: 'path',
          action: 'add-new-staff',
          title: dictionary().ADD_NEW_STAFF,
        },
        {
          type: 'path',
          action: 'deposits',
          title: dictionary().WITHDRAW,
        },
        {
          type: 'path',
          action: 'withdraw',
          title: dictionary().DEBIT,
        },
        {
          type: 'refillVehicles',
          action: 'refillVehicles',
          title: dictionary().REFILL_VEHICLES,
        },
        {
          type: 'refillDrivers',
          action: 'refillDrivers',
          title: dictionary().REFILL_DRIVERS,
        },
        {
          type: 'bulkUpload',
          submitUploadEndpoint: '/upload-csv/vehicles/commit/', //TODO
          downloadCorrectedEndpoint: '/upload-csv/corrected-vehicles-csv/download/', //TODO Bug reported
          revalidateEndpoint: '/upload-csv/vehicles/re-validate/', //TODO Bug reported
          initialFileUploadEndpoint: '/upload-csv/vehicles/validate/',
          title: dictionary().UPLOAD_ADD_NEW_VEHICLE,
          apiHeader: '/upload-csv/vehicle-template',
          fileName: 'vehicles',
        },
        {
          type: 'bulkUpload',
          submitUploadEndpoint: '/upload-csv/drivers/commit/', //TODO
          downloadCorrectedEndpoint: '/upload-csv/corrected-drivers-csv/download/', //TODO Bug reported
          revalidateEndpoint: '/upload-csv/drivers/re-validate/', //TODO Bug reported
          initialFileUploadEndpoint: '/upload-csv/drivers/validate/',
          title: dictionary().UPLOAD_ADD_NEW_DRIVER,
          apiHeader: '/upload-csv/driver-template',
          fileName: 'drivers',
        },
        // {
        //   type: 'upload',
        //   apiRoute: '/upload-csv/driver-balance/',
        //   title: dictionary().UPLOAD_DRIVER_BALANCE,
        //   apiHeader: '/upload-csv/driver-balance-transfer-template',
        // },
        {
          type: 'upload',
          apiRoute: '/upload-csv/vehicle-balance/',
          title: dictionary().UPLOAD_VEHICLE_BALANCE,
          apiHeader: '/upload-csv/vehicle-balance-transfer-template',
        },
        {
          type: 'AccessibleStaionProvidersModel',
          title: dictionary().ACCESS_SP,
        },
        {
          type: 'unbilledModel',
          title: dictionary().UNBILLED,
        },
        {
          type: 'path',
          action: 'invoices',
          title: dictionary().INVOICES,
        },
      ]
  }
}
