import { InputGroup } from '@chakra-ui/react'
import { FC, ReactNode, useRef } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

type FileUploadProps = {
  register: UseFormRegisterReturn
  children?: ReactNode
  setFileName: (name: string | null) => void
}
const FileUpload: FC<FileUploadProps> = ({ register, children, setFileName }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { ref, onChange, ...rest } = register as {
    ref: (instance: HTMLInputElement | null) => void
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
  const handleClick = () => inputRef.current?.click()

  //-----------------------------------Manual Loading for file picker-----------------------------------
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
    const file = e.target.files?.[0]
    setFileName(file ? file.name : null)
  }
  //----------------------------------------------------------------------------------------------------

  return (
    <InputGroup style={{ direction: 'rtl', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }} onClick={handleClick}>
      <input
        type={'file'}
        multiple={false}
        hidden
        {...rest}
        ref={(e) => {
          ref(e)
          inputRef.current = e
        }}
        onChange={handleChange}
      />
      <>{children}</>
    </InputGroup>
  )
}
export default FileUpload
