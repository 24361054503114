import create from 'zustand'

type DetailsStore = {
  details: any
  setDetails: (newDetails: any) => void
}

export const useDetailsStore = create<DetailsStore>((set) => ({
  details: null,
  setDetails: (newDetails) => set({ details: newDetails }),
}))
