import React from 'react'
import ViewOne from '../../components/layout/ViewOne'
import dictionary from '../../dictionary'
import { CASHING_KEY } from '../../constants'

const header = [
  {
    id: 'fuel_balance',
    key: 'vehicle.ledger_accounts',
    name: dictionary().AVIALABLE_BALANCE,
    type: 'FuelBalance',
    isRight: true,
  },
  {
    id: 'corporate.name',
    key: 'corporate.name',
    name: dictionary().CORPORATE_NAME,
    type: 'text',
  },
  {
    id: 'number_plate',
    key: 'vehicle.number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'brand',
    key: 'vehicle.vehicleBrand.brand_name',
    name: dictionary().CAR_BRAND,
    type: 'text',
  },
  {
    id: 'model',
    key: 'vehicle.vehicleModel.name',
    name: dictionary().CAR_MODEL,
    type: 'text',
  },
  {
    id: 'type',
    key: 'vehicle.vehicle_type',
    name: dictionary().VEHICLE_TYPE,
    type: 'text',
  },
  {
    id: 'fuel_consumption_benchmark',
    key: 'vehicle.fuel_consumption_benchmark',
    name: dictionary().MAX_LITERS,
    type: 'text',
  },
  {
    id: 'replenish_amount',
    key: 'vehicle.replenish_amount',
    name: dictionary().FUEL_CONSUMPTION_VALUE,
    type: 'cash',
  },
  {
    id: 'starting_balance_fuel',
    key: 'vehicle.starting_balance_fuel',
    name: dictionary().OPENING_BALANCE,
    type: 'cash',
  },
  {
    id: 'recommended_fuel_types',
    key: 'vehicle.recommended_fuel_types',
    name: dictionary().FUEL_TYPES,
    type: 'fuel-types',
  },
  {
    id: 'code',
    key: 'vehicle.code',
    name: dictionary().CODE_FOR_VEHICLE,
    type: 'text',
  },
  {
    id: 'year',
    key: 'vehicle.year',
    name: dictionary().MANUFACTURE_YEAR,
    type: 'text',
  },
  {
    id: 'is_active',
    key: 'vehicle.is_active',
    name: dictionary().STATUS,
    type: 'boolean',
  },
  {
    id: 'date',
    key: 'vehicle.created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'time',
    key: 'vehicle.created_at',
    name: dictionary().TIME,
    type: 'time',
  },
]
const actions = [
  {
    action: 'edit',
    title: dictionary().EDIT,
  },
  {
    type: 'AssignDriverToVehicleModal',
    title: dictionary().ASSIGN_DRIVER_TO_VEHICLE,
  },
  {
    type: 'transeferModal',
    title: dictionary().TRANSFER,
  },
  // {
  //   type: 'transeferBetweenEntitiesModal',
  //   title: dictionary().TRANSFER_TO_DRIVER,
  // },
  {
    type: 'transeferBetweenEntitiesModal',
    title: dictionary().TRANSFER_TO_VEHICLE,
  },
]

const Vehicle: React.FC = () => {
  return (
    <ViewOne
      actions={actions}
      header={header}
      restRoute='/vehicles'
      query={{ app: 'dashboard' }}
      dataLocation=''
      title={dictionary().VEHICLE_ID}
      cashingKey={CASHING_KEY.GET_SINGLE_VEHICLE}
      userType='vehicles'
    />
  )
}

export default Vehicle
