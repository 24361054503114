import { Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Reqiured from '../../components/core/reqiured'
import SaveButton from '../../components/core/SaveButton'
import { USER_TYPE } from '../../constants'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import { useStore } from '../../store'

const EditVehicle: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { role } = useStore()

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
  })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { userType } = useStore((state) => state)
  const { onSuccess } = useAlert()
  const stratingBalance = watch('starting_balance_fuel', 0)
  const vehicle_brand_id = watch('vehicle_brand_id')
  const vehicle_model_id = watch('vehicle_model_id')
  const [vehicleData, setVehicleData] = useState<any>({})
  const [vehicleType, setVehicleType] = useState<any>({})

  const { mutate: getVehicleData } = useMutation(() => fetch('GET', `/vehicles/${id}?app=dashboard`), {
    onSuccess: (data): void => {
      setVehicleData(data)
    },
  })
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('PATCH', `/vehicles`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/vehicles/${id}`)
    },
  })

  useEffect(() => {
    if (vehicleData) {
      const fuels: any[] = []
      if (vehicleData?.vehicle?.recommended_fuel_types !== undefined) {
        vehicleData?.vehicle?.recommended_fuel_types.map((item: any) => fuels.push(item?.id))
      }
      setValue('number_plate', vehicleData?.vehicle?.number_plate)
      setValue('corporateId', vehicleData?.corporate?.id ? [vehicleData?.corporate?.id] : [])
      setValue('vehicle_model_id', vehicleData?.vehicle?.vehicle_model_id ? [vehicleData?.vehicle?.vehicle_model_id] : [])
      setValue('vehicle_brand_id', vehicleData?.vehicle?.vehicle_brand_id ? [vehicleData?.vehicle?.vehicle_brand_id] : [])
      setVehicleType(vehicleData?.vehicle?.vehicle_type)
      setValue('max_fuel_liters', vehicleData?.vehicle?.max_fuel_liters)
      setValue('fuel_consumption_benchmark', vehicleData?.vehicle?.fuel_consumption_benchmark)
      setValue('starting_balance_fuel', vehicleData?.vehicle?.starting_balance_fuel)
      setValue('fuelTypeIds', fuels)
      setValue('code', vehicleData?.vehicle?.code)
      setValue('year', vehicleData?.vehicle?.year)
      setValue('is_active', vehicleData?.vehicle?.is_active)
    }
  }, [vehicleData])

  const editVehicle = async (formData: any) => {
    formData.id = vehicleData?.vehicle?.id
    if (formData?.number_plate === vehicleData?.vehicle?.number_plate) {
      delete formData?.number_plate
    } else {
      formData.number_plate = formData?.number_plate?.trim()
    }

    formData.starting_balance_fuel = +formData?.starting_balance_fuel
    formData.fuel_consumption_benchmark = +formData?.fuel_consumption_benchmark
    formData.max_fuel_liters = +formData?.max_fuel_liters
    formData.vehicle_brand_id = formData?.vehicle_brand_id[0]
    formData.vehicle_model_id = formData?.vehicle_model_id[0]
    formData.year = +formData?.year
    formData.vehicle_type_id = +vehicleType?.id

    if (formData?.corporateId) {
      formData.corporateId = formData.corporateId?.[0]
    }
    if (!['SUPER', 'MANAGER'].includes(role ?? '')) {
      delete formData?.corporateId
    }
    mutate(formData)
  }

  const { mutate: getModelDetails } = useMutation((brand_id: any) => fetch('GET', `/vehicle-model/${brand_id}`), {
    onSuccess: (data: any): void => {
      setVehicleType(data?.vehicleType)
    },
  })

  useEffect(() => {
    vehicle_brand_id?.length <= 0 && setValue('vehicle_model_id', [])
  }, [vehicle_brand_id?.length])

  useEffect(() => {
    vehicle_model_id?.length > 0 ? getModelDetails(vehicle_model_id[0]) : setVehicleType('')
  }, [vehicle_model_id?.length])
  useEffect(() => {
    getVehicleData()
  }, [])

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().EDIT_VEHICLE + ': ' + getValues().id}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editVehicle(getValues() as any)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editVehicle as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            {userType === USER_TYPE.ADMIN && (
              <FormControl
                isDisabled={!['SUPER', 'MANAGER'].includes(role ?? '')}
                id={dictionary().CORPORATE}
                w='40%'
                minH='100px'
                display='inline-block'
                isInvalid={!!errors.corporateId}
              >
                <FormLabel>
                  <>
                    {dictionary().CORPORATE}
                    <Reqiured />
                  </>
                </FormLabel>
                <Controller
                  name='corporateId'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      dataTest={dictionary().CORPORATE}
                      onChange={onChange}
                      searchRoute='/corporates'
                      singleRoute='/corporates'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name}
                      isInvalid={!!errors?.corporateId}
                      value={value}
                      multiple={false}
                    />
                  )}
                  rules={{
                    required: true && dictionary().REQUIRED,
                  }}
                />
                <FormErrorMessage>{errors?.corporateId?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl
              id={dictionary().NUMBER_PLATE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.number_plate || (error as any)?.data?.message === 'plate number already exists'}
            >
              <FormLabel>
                {dictionary().NUMBER_PLATE} <Reqiured />
              </FormLabel>
              <Input
                type='string'
                data-test={dictionary().NUMBER_PLATE}
                {...register('number_plate', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: /^[a-zA-Z0-9\u0600-\u06FF]+[a-zA-Z0-9\u0600-\u06FF ]*$/,
                    message: dictionary().ADD_VALID_PLATE_NUMBER,
                  },
                  validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                })}
              />
              <FormErrorMessage>{errors?.number_plate?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === 'plate number already exists' && dictionary().THIS_PLATE_NUMBER_IS_ALREADY_OCCURED}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().CAR_BRAND}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.vehicle_brand_id}
            >
              <FormLabel>
                {dictionary().CAR_BRAND} <Reqiured />
              </FormLabel>
              <Controller
                name={`vehicle_brand_id`}
                rules={{ required: dictionary().REQUIRED }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.vehicle_brand_id}
                    onChange={onChange}
                    value={value}
                    dataTest={dictionary().CAR_BRAND}
                    searchRoute='/vehicle-brand'
                    singleRoute='/vehicle-brand'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.brand_name}
                  />
                )}
              />
              <FormErrorMessage>{errors?.vehicle_brand_id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().CAR_MODEL}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.vehicle_model_id}
            >
              <FormLabel>
                {dictionary().CAR_MODEL} <Reqiured />
              </FormLabel>
              <Controller
                name={`vehicle_model_id`}
                rules={{ required: dictionary().REQUIRED }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.vehicle_model_id}
                    onChange={onChange}
                    value={value}
                    dataTest={dictionary().CAR_MODEL}
                    searchRoute={`/vehicle-model?vehicle_brand_id=${vehicle_brand_id}`}
                    singleRoute={`/vehicle-model`}
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name}
                    disabled={!vehicle_brand_id?.length}
                    enableAutoDelete={true}
                  />
                )}
              />
              <FormErrorMessage>{errors?.vehicle_model_id?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().VEHICLE_TYPE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().VEHICLE_TYPE}</FormLabel>
              <Input disabled data-test={dictionary().VEHICLE_TYPE} value={vehicleType?.name?.ar} />
            </FormControl>

            <FormControl id={dictionary().MAX_LITERS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} isInvalid={!!errors.max_fuel_liters}>
              <FormLabel>
                {dictionary().MAX_LITERS} <Reqiured />
              </FormLabel>
              <Input
                placeholder='0'
                type='number'
                data-test={dictionary().MAX_LITERS}
                {...register('max_fuel_liters', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: /^[1-9]\d*$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_NUMBERS,
                  },
                  min: {
                    value: 0,
                    message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                  },
                })}
              />
              <FormErrorMessage>{errors.max_fuel_liters?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().FUEL_CONSUMPTION_VALUE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.fuel_consumption_benchmark}
            >
              <FormLabel>{dictionary().FUEL_CONSUMPTION_VALUE}</FormLabel>
              <Input
                placeholder='0'
                type='number'
                data-test={dictionary().FUEL_CONSUMPTION_VALUE}
                {...register('fuel_consumption_benchmark', {
                  required: false,
                  min: {
                    value: 0,
                    message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                  },
                })}
              />
              <FormErrorMessage>{errors.fuel_consumption_benchmark?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().OPENING_BALANCE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.starting_balance_fuel}
            >
              <FormLabel>{dictionary().OPENING_BALANCE}</FormLabel>
              <Input
                placeholder='0'
                type='number'
                data-test={dictionary().OPENING_BALANCE}
                {...register('starting_balance_fuel', {
                  required: false,
                  min: {
                    value: 0,
                    message: dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO,
                  },
                })}
              />
              <FormErrorMessage>{errors.starting_balance_fuel?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id={dictionary().FUEL_TYPES} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} isInvalid={!!errors.fuelTypeIds}>
              <FormLabel>
                {dictionary().FUEL_TYPES} <Reqiured />{' '}
              </FormLabel>
              <Controller
                name={`fuelTypeIds`}
                control={control}
                rules={{ required: dictionary().REQUIRED }}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    value={value}
                    onChange={onChange}
                    searchRoute='/fuel-types'
                    singleRoute='/fuel-types'
                    multiple={true}
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name?.ar}
                    isInvalid={!!errors?.fuelTypeIds}
                    dataTest={dictionary().FUEL_TYPES}
                  />
                )}
              />
              {errors.fuelTypeIds?.message && <p style={{ color: '#e53e3e', paddingTop: '5px' }}>{errors.fuelTypeIds?.message}</p>}
            </FormControl>
            <FormControl
              id={dictionary().CODE_FOR_VEHICLE}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.code || (error as any)?.data?.message === `code already exists within the same corporate / corporate group !`}
            >
              <FormLabel>{dictionary().CODE_FOR_VEHICLE}</FormLabel>
              <Input
                type='string'
                data-test={dictionary().CODE_FOR_VEHICLE}
                {...register('code', {
                  required: false,
                })}
              />
              <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === `code already exists within the same corporate / corporate group !` &&
                  dictionary().THIS_CODE_IS_ALREADY_OCCURED}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().MANUFACTURE_YEAR} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} isInvalid={!!errors.year}>
              <FormLabel>
                {dictionary().MANUFACTURE_YEAR} <Reqiured />
              </FormLabel>
              <Input
                placeholder='2010'
                type='number'
                data-test={dictionary().MANUFACTURE_YEAR}
                {...register('year', {
                  required: dictionary().REQUIRED,
                  pattern: {
                    value: /^(19[0-9]{2}|20[0-9]{2}|2100)$/,
                    message: dictionary().ADD_VALID_YEAR,
                  },
                })}
              />
              <FormErrorMessage>{errors.year?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditVehicle
